import { useTheme } from 'app/AppStyling';
import CheckboxInput from 'components/selection/input/CheckboxInput';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import styled from 'styled-components';

const ManageAccessCardContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 1em;
  margin: 0.5em 1em;
  border-radius: 4px;
  border: ${() => `1px solid ${useTheme().background.medium}`};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.65' : '1')};
  background: ${({ disabled }) => (disabled ? useTheme().background.medium : useTheme().font.color.white)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0.31em;
  color: ${() => useTheme().font.color.secondary};
`;

interface ManageAccessCardProps {
  name: string;
  primarySubInfo: string;
  secondarySubInfo: string;
  isChecked: boolean;
  disabled?: boolean;
  handleCheckCallback: () => void;
}

const DefaultProps = {
  disabled: false,
};

function ManageAccessCard({
  name,
  primarySubInfo,
  secondarySubInfo,
  isChecked,
  disabled,
  handleCheckCallback,
}: ManageAccessCardProps) {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  function displaySubInfo() {
    if (primarySubInfo && secondarySubInfo) return `${primarySubInfo} | ${regionNames.of(secondarySubInfo)}`;
    if (primarySubInfo && !secondarySubInfo) return `${primarySubInfo}`;
    if (!primarySubInfo && secondarySubInfo) return `${regionNames.of(secondarySubInfo)}`;
    return '';
  }

  return (
    <ManageAccessCardContainer
      onClick={() => {
        if (!disabled) handleCheckCallback();
      }}
      disabled={disabled}
    >
      <InfoWrapper>
        <CheckboxInput checked={isChecked} />
        <TextInfoWrapper>
          <TextWrapper bold>{name}</TextWrapper>
          <SubInfoWrapper>{displaySubInfo()}</SubInfoWrapper>
        </TextInfoWrapper>
      </InfoWrapper>
    </ManageAccessCardContainer>
  );
}

ManageAccessCard.defaultProps = DefaultProps;

export default ManageAccessCard;
