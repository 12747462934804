import { InstrumentQcOverview } from '@dewire/models/definitions/api-response/instrument-overview';
import IQcTeaser from '@dewire/models/definitions/api-response/qc-teaser';
import { getInstrumentQcHistory, getInstrumentQcOverview } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import SampleIcon from 'assets/icons/SampleIcon';
import QcCardDisplay from 'components/cards/QcCardDisplay';
import Flex from 'components/flex/Flex';
import InstrumentHeader from 'components/instrument-header/InstrumentHeader';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import FilterModal from 'components/modals/FilterModal';
import FilterGroup from 'components/selection/filter/FilterGroup';
import DateInputField from 'components/selection/input/DateInputField';
import BackButton from 'components/styled-components/buttons/BackButton';
import ExpandArrow from 'components/styled-components/buttons/ExpandArrow';
import ViewButton from 'components/styled-components/buttons/ViewButton';
import ViewFlexContainer from 'components/styled-components/containers/ViewFlexContainer';
import Header3 from 'components/styled-components/headers/Header3';
import HeaderWithDateContainer from 'components/styled-components/headers/HeaderWithDateContainer';
import DateRangePicker from 'components/styled-components/selection/DateRangePicker';
import ExpandableTableRow from 'components/styled-components/table/ExpandableTableRow';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableRowBase from 'components/styled-components/table/TableRowBase';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import locateEnvironment from 'helpers/environment/locate-environment';
import filterByString from 'helpers/filter/filter-handler';
import getTranslation from 'helpers/translation/get-translation';
import { Environments, Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import QcRow from './QcRow';

const Table = styled.div`
  padding: 1em;
`;
const SpinnerContainer = styled.div`
  margin: 1em;
`;
const ResultTableHeader = styled(TableRowBase)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.background.light};
  border-bottom: 1px solid ${() => useTheme().font.color.tertiary};
`;
const LJTable = styled.div``;
const LJValue = styled.div`
  display: flex;
  flex-direction: column;
`;
const LJDiagram = styled.div``;
const TableHeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StatusContainer = styled.div`
  display: flex;
  margin: 1em;
`;
const StatusButton = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  color: ${({ selected }) => (selected ? useTheme().font.color.black : useTheme().font.color.secondary)};
  font-weight: ${({ selected }) => (selected ? 800 : 400)};
  background: ${({ selected }) => (selected ? useTheme().colors.tertiary : useTheme().font.color.white)};
  border-radius: 2px;
  height: 2.5em;
  &:hover {
    cursor: pointer;
    font-weight: 800;
  }
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
  gap: 1em;
  position: relative;
`;
const ViewButtonContainer = styled.div`
  display: flex;
  margin-bottom: 2em;
  gap: 3em;
`;

type LJResult = {
  val: string;
};

function QcView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const headersState = useAppSelector((state) => state.headers);

  const [methodFilter, setMethodFilter] = useState<string[]>([]);
  const [profileFilter, setProfileFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [expandedQc, setExpandedQc] = useState('');
  const [instrumentQcOverview, setInstrumentQcOverview] = useState<InstrumentQcOverview>();
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [qcHistory, setQcHistory] = useState<IQcTeaser[]>([]);
  const [apiError, setApiError] = useState('');
  const [historyApiError, setHistroyApiError] = useState('');
  const [view, setView] = useState('results');
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 90)));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [LJStatus, setLJStatus] = useState('');
  const [LJResults] = useState<LJResult[]>([{ val: 'rbc1' }, { val: 'rbc2' }]);
  const [expandedLJ, setExpandedLJ] = useState('');
  const qcArray = ['Successful QC', 'Failed QC', 'Flagged QC'];
  const methodArray = ['Open tube', 'Prediluted sample', 'Cap piercer', 'Micro-capillary', 'Autosampler', 'Unknown'];

  const filterActive = methodFilter.length !== 0 || profileFilter.length !== 0 || statusFilter.length !== 0;

  const handleExpandRow = (isExpanded: boolean, itemId: string) => {
    if (isExpanded) {
      setExpandedLJ('');
    } else {
      setExpandedLJ(itemId);
    }
  };

  const handleFilter = (selection: string, filterType: 'method' | 'profile' | 'status') => {
    switch (filterType) {
      case 'method': {
        filterByString(methodFilter, setMethodFilter, selection);
        break;
      }
      case 'profile': {
        filterByString(profileFilter, setProfileFilter, selection);
        break;
      }
      case 'status': {
        filterByString(statusFilter, setStatusFilter, selection);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleFilterClear = () => {
    setMethodFilter([]);
    setProfileFilter([]);
    setStatusFilter([]);
  };

  const onChangeDate = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleExpandCallback = (isExpanded: boolean, qcId: string) => {
    if (isExpanded) {
      setExpandedQc('');
    } else {
      setExpandedQc(qcId);
    }
  };

  const fetchHistory = () => {
    if (id) {
      getInstrumentQcHistory(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setQcHistory(res.data.result);
        } else {
          setHistroyApiError(res.status);
        }
        setLoadingHistory(false);
      });
    } else navigate('/');
  };

  const fetchOverview = () => {
    if (id) {
      getInstrumentQcOverview(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentQcOverview(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
    }
  };

  useEffect(() => {
    fetchHistory();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchOverview();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (instrumentQcOverview || apiError) setLoading(false);
  }, [instrumentQcOverview, apiError]);

  const qcHistoryView = qcHistory
    .filter(
      (qc) =>
        (!methodFilter.length || methodFilter.find((m) => qc.method.toLowerCase().indexOf(m.toLowerCase()) !== -1)) &&
        (!profileFilter.length || profileFilter.includes(qc.profile)) &&
        (!statusFilter.length || statusFilter.includes(qc.status))
    )
    .filter((qc) => {
      startDate.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59);
      return new Date(qc.date) >= startDate && new Date(qc.date) <= endDate;
    })
    .map((qc, index) => (
      <QcRow
        key={qc.id.toString()}
        qc={qc}
        nextDate={qcHistory[index + 1] ? qcHistory[index + 1].date : qc.date}
        expandRowCallback={(open: boolean) => handleExpandCallback(open, qc.id.toLocaleString())}
        expanded={expandedQc === qc.id.toLocaleString()}
      />
    ));

  const ljTableResults = LJResults.map((item: LJResult) => (
    <>
      <ExpandableTableRow
        textAlign="center"
        gridExpression="15% 25% 55% 7%"
        open={expandedLJ === item.val}
        onClick={() => handleExpandRow(expandedLJ === item.val, item.val)}
      >
        <ExpandArrow>
          <ExpandArrowIcon open={expandedLJ === item.val} />
        </ExpandArrow>
      </ExpandableTableRow>
      {expandedLJ === item.val && (
        <div>
          <LJValue>RBC 10l</LJValue>
          <LJDiagram>{item.val}</LJDiagram>
        </div>
      )}
    </>
  ));

  return (
    <div>
      {id && <InstrumentHeader instrumentId={id} />}
      <ViewFlexContainer>
        <nav>
          <BackButton onClick={() => navigate(-1)}>
            <ArrowBackIcon size="small" />
            <p>{getTranslation('Instrument dashboard')}</p>
          </BackButton>
        </nav>
        <section>
          <HeaderWithDateContainer>
            <Header3>{getTranslation('Quality Control')}</Header3>
          </HeaderWithDateContainer>
          <Flex gap="1em" minHeight="19">
            {loading ? (
              <LoadingSpinner containerBased />
            ) : (
              <>
                {instrumentQcOverview?.qc.map((qc) => (
                  <QcCardDisplay
                    key={qc.displayName}
                    qc={{
                      ...qc,
                      sampleCreatedAt: qc.time,
                      reportCreatedAt: qc.expiryTime,
                      controlBloodExpiry: qc.expiryTime,
                    }}
                    detailedView
                  />
                ))}
                {apiError !== '' && <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>}
              </>
            )}
          </Flex>
        </section>
        <section>
          {headersState.accesses.instrumentMonitoring ? (
            <ViewButtonContainer>
              {locateEnvironment([Environments.Dev, Environments.Cont, Environments.Test]) && (
                <ViewButton type="button" active={view === 'diagram'} onClick={() => setView('diagram')}>
                  {getTranslation('L-J Diagram')}
                </ViewButton>
              )}
              <ViewButton
                type="button"
                active={view === 'results'}
                onClick={() => {
                  setView('results');
                }}
              >
                {getTranslation('Results')}
              </ViewButton>
            </ViewButtonContainer>
          ) : (
            <Header3>{getTranslation('Results')}</Header3>
          )}
          <HistoryTable>
            <TableHeaderBar>
              {view === 'diagram' && (
                <StatusContainer>
                  <StatusButton selected={LJStatus === 'normal'} onClick={() => setLJStatus('normal')}>
                    Normal
                  </StatusButton>
                  <StatusButton selected={LJStatus === 'high'} onClick={() => setLJStatus('high')}>
                    High
                  </StatusButton>
                  <StatusButton selected={LJStatus === 'low'} onClick={() => setLJStatus('low')}>
                    Low
                  </StatusButton>
                  <StatusButton selected={LJStatus === 'icon'} onClick={() => setLJStatus('icon')}>
                    <SampleIcon />
                  </StatusButton>
                </StatusContainer>
              )}
              <FilterContainer>
                <DateRangePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  monthsShown={2}
                  filterDate={(date) => date < new Date()}
                  customInput={<DateInputField value={`${startDate} ${endDate}`} onClick={undefined} />}
                  className="react-datepicker"
                />

                {view === 'results' && (
                  <FilterModal isFiltering={filterActive} clearCallback={() => handleFilterClear()}>
                    <>
                      <FilterGroup
                        groupTitle="Result"
                        filterOptions={[Status.Success, Status.Failure, Status.Warning]}
                        filterOptionsNames={qcArray}
                        filterArray={statusFilter}
                        onFilterCallback={(filterValue) => handleFilter(filterValue, 'status')}
                      />
                      <FilterGroup
                        groupTitle="Profile"
                        filterOptions={instrumentQcOverview?.qc.map((option) => option.displayName)}
                        filterArray={profileFilter}
                        onFilterCallback={(filterValue) => handleFilter(filterValue, 'profile')}
                      />
                      <FilterGroup
                        groupTitle="Method"
                        groupWidth={11.5}
                        filterOptions={methodArray}
                        filterArray={methodFilter}
                        onFilterCallback={(filterValue) => handleFilter(filterValue, 'method')}
                      />
                    </>
                  </FilterModal>
                )}
              </FilterContainer>
            </TableHeaderBar>
            {view === 'results' && (
              <Table>
                <ResultTableHeader gridExpression="10% 20% 20% 45% 7%" textAlign="center">
                  <p>{getTranslation('Result')}</p>
                  <p>{getTranslation('Profile')}</p>
                  <p>{getTranslation('Method')}</p>
                  <p>{getTranslation('Date & Time')}</p>
                  <div />
                </ResultTableHeader>
                {loadingHistory ? (
                  <SpinnerContainer>
                    <LoadingSpinner containerBased />
                  </SpinnerContainer>
                ) : (
                  <div>
                    {qcHistoryView}
                    {historyApiError && <TextWrapper color={TextColor.Danger}>{historyApiError}</TextWrapper>}
                  </div>
                )}
              </Table>
            )}
            {view === 'diagram' && <LJTable>{ljTableResults}</LJTable>}
          </HistoryTable>
        </section>
      </ViewFlexContainer>
    </div>
  );
}

export default QcView;
