import { FormControlLabel } from '@mui/material';
import { CheckboxInner, CheckboxOuter } from 'components/styled-components/selection/Checkbox';

interface CheckboxInputProps {
  text?: string;
  checked: boolean;
  rounded?: boolean;
}

const DefaultProps = {
  text: '',
  rounded: false,
};

function CheckboxInput({ text, checked, rounded }: CheckboxInputProps) {
  return (
    <FormControlLabel
      label={text}
      control={
        <CheckboxOuter rounded={rounded ?? false}>
          <CheckboxInner checked={checked} rounded={rounded ?? false} />
        </CheckboxOuter>
      }
    />
  );
}

CheckboxInput.defaultProps = DefaultProps;

export default CheckboxInput;
