import PdfWrapper from 'components/styled-components/wrappers/PdfWrapper';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const UserManualContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
`;

interface UserManualProps {
  color: string;
  inMenu?: boolean;
}

function UserManual({ color, inMenu }: UserManualProps) {
  const headersState = useAppSelector((state) => state.headers);

  return (
    <UserManualContainer>
      {(headersState.accesses.organization.instruments.edit && (
        <PdfWrapper text={inMenu ? 'User Manual' : 'Admin'} pdfRef="documents/userManualAdmin.pdf" textColor={color} />
      )) ||
        (!headersState.accesses.organization.instruments.edit && !headersState.accesses.instrumentMonitoring && (
          <PdfWrapper
            text={inMenu ? 'User Manual' : 'Operator'}
            pdfRef="documents/userManualOperator.pdf"
            textColor={color}
          />
        )) ||
        (headersState.accesses.organization.instruments.edit && headersState.accesses.instrumentMonitoring && (
          <PdfWrapper
            text={inMenu ? 'User Manual' : 'Service technician'}
            pdfRef="documents/userManualST.pdf"
            textColor={color}
          />
        )) ||
        (!headersState.accesses.organization.instruments.edit && headersState.accesses.instrumentMonitoring && (
          <PdfWrapper
            text={inMenu ? 'User Manual' : 'Instrument coordinator'}
            pdfRef="documents/userManualIC.pdf"
            textColor={color}
          />
        ))}
    </UserManualContainer>
  );
}

UserManual.defaultProps = {
  inMenu: false,
};

export default UserManual;
