import { useTheme } from 'app/AppStyling';
import BouleIcon from 'assets/icons/BouleIcon';
import { VERSION_LABEL } from 'config/config';
import locateEnvironment from 'helpers/environment/locate-environment';
import { useCurrentBreakpoint } from 'helpers/window/use-current-breakpoint';
import { Environments } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import FooterMenu from './FooterMenu';

const FooterContainer = styled.div`
  background: ${({ theme }) => theme.font.color.primary};
`;
const TopSection = styled.div`
  background: ${({ theme }) => theme.font.color.primary};
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BottomSection = styled.div`
  background: ${({ theme }) => theme.font.color.primary};
  padding: 1.5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${() => useTheme().font.color.white};
`;
const IconContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const BouleBrand = styled.div`
  color: ${() => useTheme().font.color.gray};
`;
const Divider = styled.div`
  margin: 0rem 0.5rem;
  color: ${() => useTheme().font.color.gray};
`;
const KnightecBrand = styled.div`
  color: ${() => useTheme().font.color.gray};
  :hover {
    color: ${() => useTheme().icon.blue};
    cursor: pointer;
  }
`;

function FooterWrapper() {
  const currentBreakPoint = useCurrentBreakpoint();
  const [viewBreakPoint, setViewBreakPoint] = useState(currentBreakPoint);

  useEffect(() => {
    setViewBreakPoint(currentBreakPoint);
  }, [currentBreakPoint]);

  const handleNewTabOpen = (icon: string) => {
    switch (icon) {
      case 'linkedIn':
        window.open('https://www.linkedin.com/company/boule-medical-ab/');
        break;
      case 'youtube':
        window.open('https://www.youtube.com/channel/UCrdun7UaAP1LORITFZqa6pg');
        break;
      case 'facebook':
        window.open('https://www.facebook.com/BouleDiagnostics/');
        break;
      case 'knightec':
        window.open('https://www.knightec.se//');
        break;
      default:
        break;
    }
  };

  return (
    <FooterContainer>
      <TopSection>
        <IconContainer to="/test">
          <BouleIcon width={200} height={54} color={useTheme().font.color.white} />
        </IconContainer>
        <FooterMenu handleLinkCallback={(link: string) => handleNewTabOpen(link)} />
      </TopSection>
      <BottomSection>
        <BouleBrand>© Boule 2022</BouleBrand>
        <Divider>|</Divider>
        <KnightecBrand onClick={() => handleNewTabOpen('knightec')}>Created By Knightec</KnightecBrand>
        <Divider>|</Divider>
        <BouleBrand>{`Version: ${VERSION_LABEL}`}</BouleBrand>
        {locateEnvironment([Environments.Dev, Environments.Cont, Environments.Test]) && <Divider>|</Divider>}
        {locateEnvironment([Environments.Dev, Environments.Cont, Environments.Test]) && (
          <BouleBrand>{viewBreakPoint}px</BouleBrand>
        )}
      </BottomSection>
    </FooterContainer>
  );
}

export default FooterWrapper;
