import { InstrumentOverviewCard } from '@dewire/models/definitions/api-response/qc-overview-card';
import { StatusType } from '@dewire/models/definitions/status-type';
import { useTheme } from 'app/AppStyling';
import RemoveIcon from 'assets/icons/RemoveIcon';
import ExtraInstrumentsCard from 'components/cards/ExtraInstrumentsCard';
import QcCardAction from 'components/cards/QcCardAction';
import { Status } from 'interfaces/common';
import { LatLngTuple, Map } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MAPBOX_STYLE_ID, MAPBOX_TOKEN, MAPBOX_USERNAME } from '../../config/config';
import SiteMarker from './SiteMarker';

const CardContainer = styled.div`
  position: absolute;
  z-index: 999;
  left: 0;
  bottom: 0;
  margin: 1em;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 15em);
  gap: 1em;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: -12.5px;
  top: -10px;
  display: flex;
  margin: 0 0.3em;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 2em;
  width: 2em;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.7);
  font-weight: 900;
  background-color: ${({ theme: { colors } }) => colors.danger.iconDetails};
  color: white;
`;

const CardWrapperLink = styled(Link)`
  text-decoration: none;
  color: ${() => useTheme().font.color.black};
  transition: 50ms box-shadow ease-in-out;
  :hover {
    box-shadow: 3px 3px 10px ${() => useTheme().shadow.secondary};
  }
`;

interface IMapProps {
  qcs: InstrumentOverviewCard[];
  setTab: React.Dispatch<React.SetStateAction<'list' | 'map'>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const isNumber = (item: number | undefined): item is number => !!item;

function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

const COORDINATES_STOCKHOLM = { latitude: 59.334591, longitude: 18.06324 };
const defaultBounds = [
  [COORDINATES_STOCKHOLM.latitude - 10, COORDINATES_STOCKHOLM.longitude - 10] as LatLngTuple,
  [COORDINATES_STOCKHOLM.latitude + 10, COORDINATES_STOCKHOLM.longitude + 10] as LatLngTuple,
];
const maxBounds = [[-90, -180] as LatLngTuple, [90, 180] as LatLngTuple];

function GetWorstStatus(statuses: StatusType[]): StatusType {
  if (statuses.includes(Status.Failure)) return Status.Failure;
  if (statuses.includes(Status.Warning)) return Status.Warning;
  if (statuses.includes(Status.Unknown)) return Status.Unknown;
  return Status.Success;
}

function groupOnSite(instruments: InstrumentOverviewCard[]): InstrumentOverviewCard[][] {
  return instruments
    .map((i) => `${i.siteLat}, ${i.siteLong}`)
    .filter(onlyUnique)
    .map((key) => instruments.filter((i) => key === `${i.siteLat}, ${i.siteLong}`));
}

function InstrumentMap({ qcs, setTab, setSearch }: IMapProps) {
  const [selectedInstrumentsIndex, setSelectedInstrumentsIndex] = useState(-1);
  const [bounds, setBounds] = useState<LatLngTuple[]>(defaultBounds);
  const [sites, setSites] = useState<InstrumentOverviewCard[][]>();
  const [maxCards, setMaxCards] = useState(0);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const mapRef = useRef<Map>(null);

  function handleSetInstruments(index: number) {
    if (!isToggled) setSelectedInstrumentsIndex(index);
  }

  function deToggleInstrumentSelection() {
    setSelectedInstrumentsIndex(-1);
    setIsToggled(false);
  }

  function handlePinClick(index: number) {
    if (selectedInstrumentsIndex === index) deToggleInstrumentSelection(); // user clicked same pin twice --> detoggle

    if (!isToggled) setIsToggled(true);
    setSelectedInstrumentsIndex(index);
  }

  function extraInstrumentsRedirect(siteName: string) {
    setSearch(siteName);
    setTab('list');
  }

  useEffect(() => {
    const latitudes = qcs
      .map((q) => q.siteLat)
      .filter(isNumber)
      .sort();

    const longitudes = qcs
      .map((q) => q.siteLong)
      .filter(isNumber)
      .sort();
    if (latitudes && latitudes.length > 0 && longitudes && longitudes.length > 0) {
      const latDiff = latitudes[latitudes.length - 1] - latitudes[0];
      const longDiff = longitudes[latitudes.length - 1] - longitudes[0];
      const boundMargin = Math.max(0.01, Math.max(latDiff, longDiff) * 0.1);
      setBounds([
        [latitudes[0] - boundMargin, longitudes[0] - boundMargin] as LatLngTuple,
        [latitudes[latitudes.length - 1] + boundMargin, longitudes[longitudes.length - 1] + boundMargin] as LatLngTuple,
      ]);
    }
    setSites(groupOnSite(qcs));
  }, [qcs]);

  useEffect(() => {
    if (sites) setMaxCards(Math.floor(windowWidth / 280) - 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstrumentsIndex, windowWidth]);

  useEffect(() => {
    const changeWidth = function onWindowWidthChange() {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyToBounds(bounds, {
        duration: 1,
      });
    }
  }, [bounds]);

  return (
    <MapContainer id="map" bounds={defaultBounds} scrollWheelZoom ref={mapRef} minZoom={1.5} maxBounds={maxBounds}>
      <TileLayer
        attribution={`© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>`}
        url={`https://api.mapbox.com/styles/v1/${MAPBOX_USERNAME}/${MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`}
        noWrap
        bounds={maxBounds}
      />

      {sites &&
        sites.map((instrumentList, index) => {
          if (!instrumentList[0].siteName || !instrumentList[0].siteLat || !instrumentList[0].siteLong) return false;
          return (
            <SiteMarker
              key={instrumentList[0].serialNumber}
              coordinates={[instrumentList[0].siteLat, instrumentList[0].siteLong]}
              setInstrumentsCallback={(instrumentIndex: number) => handleSetInstruments(instrumentIndex)}
              instrumentListIndex={index}
              numberOfInstruments={instrumentList.length}
              clickCallback={(pinIndex: number) => handlePinClick(pinIndex)}
              status={GetWorstStatus(instrumentList.map((qc) => qc.status))}
              active={index === selectedInstrumentsIndex}
            />
          );
        })}

      <CardContainer>
        {selectedInstrumentsIndex > -1 && sites && (
          <>
            {isToggled && (
              <IconWrapper onClick={() => deToggleInstrumentSelection()}>
                <RemoveIcon />
              </IconWrapper>
            )}
            {sites[selectedInstrumentsIndex].map(
              (instrumentOverview, elementIndex) =>
                elementIndex <= maxCards && (
                  <CardWrapperLink
                    to={`/instrument/${instrumentOverview.serialNumber}`}
                    key={instrumentOverview.serialNumber}
                  >
                    <QcCardAction
                      qc={instrumentOverview}
                      key={instrumentOverview.serialNumber}
                      onMouseLeaveCallback={(instrumentIndex: number) => handleSetInstruments(instrumentIndex)}
                      isToggled={isToggled}
                    />
                  </CardWrapperLink>
                )
            )}
            {sites[selectedInstrumentsIndex].length > maxCards &&
              Math.abs(sites[selectedInstrumentsIndex].length - maxCards - 1) > 0 && (
                <ExtraInstrumentsCard
                  onClickCallback={() => extraInstrumentsRedirect(sites[selectedInstrumentsIndex][0].siteName)}
                  extra={Math.abs(sites[selectedInstrumentsIndex].length - maxCards - 1)}
                />
              )}
          </>
        )}
      </CardContainer>
    </MapContainer>
  );
}

export default InstrumentMap;
