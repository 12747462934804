import { Site } from '@dewire/models/definitions/api-response/site';
import { useTheme } from 'app/AppStyling';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import PenIcon from 'assets/icons/PenIcon';
import InfoIconModal from 'components/modals/InfoIconModal';
import InTableButton from 'components/styled-components/buttons/InTableButton';
import TableRow from 'components/styled-components/table/TableRow';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const RowContainer = styled.div<{ isExpanded: boolean }>`
  border-bottom: ${({ isExpanded }) => (isExpanded ? `1px solid ${useTheme().font.color.tertiary}` : 'none')};
`;
const RowItem = styled.div<{ icon?: boolean }>`
  white-space: nowrap;
  overflow: ${({ icon }) => (icon ? '' : 'hidden')};
  text-overflow: ellipsis;
  max-width: 20em;
  ${() => bpTheme.breakpoints.down('lg')} {
    max-width: 12em;
  }
`;
const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 8em 4em;
`;
const IconContainer = styled.div`
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid ${() => useTheme().colors.blue};
  }
`;

interface SiteRowProps {
  site: Site;
  expanded: boolean;
  instrumentsCallback: () => void;
  manageSiteCallback: () => void;
}

function SiteRow({ site, expanded, instrumentsCallback, manageSiteCallback }: SiteRowProps) {
  const sitesAccess = useAppSelector((state) => state.headers.accesses.organization);

  const isEditable = () => {
    if (
      (sitesAccess.sites.view && sitesAccess.sites.edit) ||
      (sitesAccess.subsites.view && sitesAccess.subsites.edit && site.formData.parentSiteId)
    )
      return true;
    return false;
  };

  return (
    <RowContainer isExpanded={expanded}>
      <TableRow className="siteTables" gridExpression="27% 20% 32% 19% 6%" selected={expanded}>
        <RowItem className="siteName">{site.name}</RowItem>
        <RowItem>{site.city}</RowItem>
        <RowItem>{site.address}</RowItem>
        <InTableButton onClick={() => instrumentsCallback()}>
          {site.numOfInstruments}
          <ExpandArrowIcon open={false} color="black" />
        </InTableButton>
        {isEditable() ? (
          <RowItem onClick={() => manageSiteCallback()}>
            <IconContainer>
              <PenIcon />
            </IconContainer>
          </RowItem>
        ) : (
          <RowItem icon>
            <InfoIconModal icon={<InfoIcon />}>
              <InfoItem>
                <h4>Address: </h4>
                {site.formData.address ? site.formData.address : 'No address'}
              </InfoItem>
              <InfoItem>
                <h4>City: </h4>
                {site.formData.city ? site.formData.city : 'No city'}
              </InfoItem>
              <InfoItem>
                <h4>Postal code: </h4>
                {site.formData.postalCode ? site.formData.postalCode : 'No postal code'}
              </InfoItem>
              <InfoItem>
                <h4>E-mail: </h4>
                {site.formData.email ? site.formData.email : 'No email'}
              </InfoItem>
              <InfoItem>
                <h4>Phone: </h4>
                {site.formData.phoneNumber ? site.formData.phoneNumber : 'No phone number'}
              </InfoItem>
            </InfoIconModal>
          </RowItem>
        )}
      </TableRow>
    </RowContainer>
  );
}

export default SiteRow;
