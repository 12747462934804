import { SimpleUserPermissionInput } from '@dewire/models/definitions/form-input/user-permission-input';
import { manageUser } from 'redux/slices/users.slice';
import { store } from 'redux/store';

export default async function manageExistingUserRole(
  newUser: SimpleUserPermissionInput,
  onManagedUser: () => void,
  onManageUserFailed: () => void
) {
  const headersState = store.getState().headers;
  store
    .dispatch(manageUser({ session: headersState, user: newUser }))
    .then(() => onManagedUser())
    .catch(() => onManageUserFailed());
}
