import { UnitLists } from '@dewire/dbservices/models/permission-mangement';
import { AccessType } from '@dewire/models/definitions/api-response/access-type';
import { Accesses } from '@dewire/models/definitions/api-response/accesses';
import { BouleUser } from '@dewire/models/definitions/api-response/boule-user';
import { BouleUserDetails } from '@dewire/models/definitions/api-response/boule-user-details';
import { Customer } from '@dewire/models/definitions/api-response/customer';
import type { CustomerDeleteResponse } from '@dewire/models/definitions/api-response/customer-delete-respons';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import InstrumentBackground from '@dewire/models/definitions/api-response/instrument-background';
import InstrumentCycleCounter from '@dewire/models/definitions/api-response/instrument-cycle-counter';
import InstrumentCycleDayDetails from '@dewire/models/definitions/api-response/instrument-cycle-day-details';
import InstrumentCycleDaySummary from '@dewire/models/definitions/api-response/instrument-cycle-day-summary';
import type { InstrumentDeleteResponse } from '@dewire/models/definitions/api-response/instrument-delete-respons';
import InstrumentHeader from '@dewire/models/definitions/api-response/instrument-header';
import { InstrumentLatestCleaningCycles } from '@dewire/models/definitions/api-response/instrument-latest-cleaning-cycles';
import { InstrumentQcOverview } from '@dewire/models/definitions/api-response/instrument-overview';
import { InstrumentProductModel } from '@dewire/models/definitions/api-response/instrument-product-models';
import { Market } from '@dewire/models/definitions/api-response/market';
import { QcDetails } from '@dewire/models/definitions/api-response/qc-detail';
import { InstrumentOverviewCard } from '@dewire/models/definitions/api-response/qc-overview-card';
import QcTeaser from '@dewire/models/definitions/api-response/qc-teaser';
import Session from '@dewire/models/definitions/api-response/session';
import { Site } from '@dewire/models/definitions/api-response/site';
import type { SiteDeleteResponse } from '@dewire/models/definitions/api-response/site-delete-respons';
import { UploadHistoryEntry } from '@dewire/models/definitions/api-response/upload-history-entry';
import { ManageOriginLists } from '@dewire/models/definitions/api-response/user-admin/manage-origin-list';
import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import { InstrumentDeleteInput } from '@dewire/models/definitions/form-input/instrument-delete-input';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import { OrgUnitDeleteInput } from '@dewire/models/definitions/form-input/org-unit-delete-input';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import { SimpleUserPermissionInput } from '@dewire/models/definitions/form-input/user-permission-input';
import { OriginTypes } from '@dewire/models/definitions/origin-types';
import dayjs from 'dayjs';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import initHeadersService from 'helpers/local-storage-service/headers-service';
import clearUserSession from 'helpers/resets/clear-user-session';
import { Status } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';
import Response from 'interfaces/response';
import lodash from 'lodash';
import { setAccesses, setHeadersWithPayload, setUserName } from 'redux/slices/headers.slice';
import { store } from 'redux/store';

import { BACKEND_API_BASE_URL, LOGIN_HOSTED_UI_URL } from '../config/config';
import { getAuthHeaders } from './utils/api-utils';

interface AuthData {
  data: {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    token_type: string;
  };
  status: string;
  accesses: Accesses;
  userName: string;
}
// keep user obj in sync between store and local storage
initHeadersService();

async function authenticate(authCode: string): Promise<AuthData> {
  const body = JSON.stringify({ authorizationCode: authCode });

  const headers = new Headers();
  headers.append('Accept', `*/*`);
  headers.append('Content-type', `application/json`);

  const authenticateResponse = await fetch(`${BACKEND_API_BASE_URL}/authorize/login`, {
    method: 'POST',
    headers,
    body,
  });

  const authenticationData = (await authenticateResponse.json()) as AuthData;

  store.dispatch(
    setHeadersWithPayload({
      accessToken: authenticationData.data.access_token,
      idToken: authenticationData.data.id_token,
      refreshToken: authenticationData.data.refresh_token,
      timestamp: dayjs().unix() + authenticationData.data.expires_in,
    })
  );
  store.dispatch(setAccesses(authenticationData.accesses));
  store.dispatch(setUserName(authenticationData.userName));

  window.history.replaceState(null, '', window.location.pathname);

  return authenticationData;
}

export async function renewAccessTokens(session: Session): Promise<AuthData> {
  const body = JSON.stringify({ refreshToken: session.refreshToken });

  const headers = getAuthHeaders(session);
  headers.append('Accept', `*/*`);
  headers.append('Content-type', `application/json`);

  const authenticateResponse = await fetch(`${BACKEND_API_BASE_URL}/authorize/refresh`, {
    method: 'POST',
    headers,
    body,
  });

  const authenticationData = (await authenticateResponse.json()) as AuthData;
  store.dispatch(
    setHeadersWithPayload({
      accessToken: authenticationData.data.access_token,
      idToken: authenticationData.data.id_token,
      refreshToken: session.refreshToken,
      timestamp: dayjs().unix() + authenticationData.data.expires_in,
    })
  );

  return authenticationData;
}

async function getRequest<T>(session: Session, url: string, errorReturn: T): Promise<FullGetResponse<T>> {
  try {
    const response = await fetch(`${BACKEND_API_BASE_URL}/${url}`, {
      headers: getAuthHeaders(session),
    });
    if (response.status === 401) {
      clearUserSession();
      return { status: 'Not authorized', data: { result: errorReturn } };
    }
    return await response.json();
  } catch (e) {
    provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Api did not respond');
    return { status: 'Api did not respond', data: { result: errorReturn } };
  }
}

export const getFileUploadUrl = async (
  fileName: string,
  isAssay: boolean,
  session: Session
): Promise<FullGetResponse<{ url: string }>> => {
  const response = await fetch(`${BACKEND_API_BASE_URL}/upload/getUrl?fileName=${fileName}&assayData=${isAssay}`, {
    headers: getAuthHeaders(session),
  });
  return response.json();
};

export const getQcOverview = async (
  session: Session
): Promise<{ status: string; data: Response<InstrumentOverviewCard[]> }> =>
  getRequest<InstrumentOverviewCard[]>(session, 'dashboard/qc/instruments', []);

export const getInstrumentHeader = async (
  id: string,
  session: Session
): Promise<FullGetResponse<InstrumentHeader | undefined>> =>
  getRequest<InstrumentHeader | undefined>(session, `organization/instrument/${id}`, undefined);

export const getInstrumentQcOverview = async (
  id: string,
  session: Session
): Promise<FullGetResponse<InstrumentQcOverview>> =>
  getRequest<InstrumentQcOverview>(session, `organization/instrument/${id}/qc-overwiew`, {
    latestExport: undefined,
    qc: [],
  });

export const getInstrumentQcHistory = async (id: string, session: Session): Promise<FullGetResponse<QcTeaser[]>> =>
  getRequest<QcTeaser[]>(session, `organization/instrument/${id}/history`, []);

export const getInstrumentQcDetails = async (
  id: string,
  qcId: BigInt,
  session: Session
): Promise<FullGetResponse<QcDetails | undefined>> =>
  getRequest<QcDetails | undefined>(session, `organization/instrument/${id}/history/${qcId}`, undefined);

export const getInstrumentTotalCycles = async (
  id: string,
  session: Session
): Promise<FullGetResponse<InstrumentCycleCounter | undefined>> =>
  getRequest<InstrumentCycleCounter | undefined>(session, `organization/instrument/${id}/cycle-count`, undefined);

export const getInstrumentLatestCleanCycles = async (
  id: string,
  session: Session
): Promise<FullGetResponse<InstrumentLatestCleaningCycles | undefined>> =>
  getRequest<InstrumentLatestCleaningCycles | undefined>(
    session,
    `organization/instrument/${id}/latest-cleaning-cycles`,
    undefined
  );

export const getLatestBackground = async (
  id: string,
  session: Session
): Promise<FullGetResponse<InstrumentBackground | undefined>> =>
  getRequest<InstrumentBackground | undefined>(session, `organization/instrument/${id}/latest-background`, undefined);

export const getInstrumentCycleHistory = async (
  id: string,
  session: Session
): Promise<FullGetResponse<InstrumentCycleDaySummary[]>> =>
  getRequest<InstrumentCycleDaySummary[]>(session, `organization/instrument/${id}/cycle-history`, []);

export const getInstrumentCycleHistoryDay = async (
  id: string,
  date: Date,
  session: Session
): Promise<FullGetResponse<InstrumentCycleDayDetails | undefined>> =>
  getRequest<InstrumentCycleDayDetails | undefined>(
    session,
    `organization/instrument/${id}/cycle-history/${date.toISOString()}`,
    undefined
  );

export const postSignOut = async (
  session: Session
): Promise<{ status: string; data: Response<InstrumentCycleDayDetails> }> => {
  const response = await fetch(`${BACKEND_API_BASE_URL}/authorize/signout`, {
    method: 'POST',
    headers: getAuthHeaders(session),
  });
  return response.json();
};

export const getIdentityProviders = async () => {
  const mockedResponse = [
    { name: 'Knightec', url: LOGIN_HOSTED_UI_URL },
    { name: 'Boule', url: '' },
  ];
  return mockedResponse;
};

export { authenticate };

export const getUploadedFiles = async (session: Session): Promise<FullGetResponse<UploadHistoryEntry[]>> =>
  getRequest<UploadHistoryEntry[]>(session, `upload/history`, []);

export const getPersonalUser = (session: Session): Promise<FullGetResponse<BouleUserDetails | undefined>> =>
  getRequest<BouleUserDetails | undefined>(session, 'user', undefined);

export const managePersonalUser = async (
  session: Session,
  user: SimpleUserPermissionInput
): Promise<{ status: string; data: Response<string> }> => {
  const body = JSON.stringify(user);
  const response = await fetch(`${BACKEND_API_BASE_URL}/user`, {
    method: 'PUT',
    headers: getAuthHeaders(session),
    body,
  });

  if (response.status !== 200) {
    throw new Error('unsuccessfull update');
  }
  const result = response.json();
  return result;
};

export const getAccessTypes = (session: Session): Promise<FullGetResponse<AccessType[]>> =>
  getRequest<AccessType[]>(session, 'user/accesstype', []);

export const getUsers = (session: Session): Promise<FullGetResponse<BouleUser[]>> =>
  getRequest<BouleUser[]>(session, 'user/admin', []);

export const getUserDetails = (session: Session, id: string): Promise<FullGetResponse<BouleUserDetails | undefined>> =>
  getRequest<BouleUserDetails | undefined>(session, `user/admin/${id}`, undefined);

export const getOrigins = (
  session: Session,
  originType: OriginTypes,
  unitId: string,
  userId: string
): Promise<FullGetResponse<UnitLists | undefined>> =>
  getRequest<UnitLists | undefined>(
    session,
    `user/origin?unitOrigin=${originType}&unitId=${unitId}&userId=${userId}`,
    undefined
  );

export const getOriginsOverview = (session: Session): Promise<FullGetResponse<ManageOriginLists | undefined>> =>
  getRequest<ManageOriginLists | undefined>(session, 'user/origin/manage-origin', undefined);

export const getMarkets = async (session: Session): Promise<FullGetResponse<Market[]>> =>
  getRequest<Market[]>(session, `organization/market`, []);

export const getInstrumentProductModels = async (
  session: Session
): Promise<FullGetResponse<InstrumentProductModel[]>> =>
  getRequest<InstrumentProductModel[]>(session, `organization/instrument/instrument-product-models`, []);

export const getDistributors = async (session: Session): Promise<FullGetResponse<Distributor[]>> =>
  getRequest<Distributor[]>(session, `organization/distributor`, []);

export const getCustomers = async (session: Session): Promise<FullGetResponse<Customer[]>> =>
  getRequest<Customer[]>(session, `organization/customer`, []);

// FixSites: API request
export const getSites = async (session: Session): Promise<FullGetResponse<Site[]>> =>
  getRequest<Site[]>(session, `organization/site`, []);

export const getInstruments = async (session: Session): Promise<FullGetResponse<Instrument[]>> =>
  getRequest<Instrument[]>(session, `organization/instrument`, []);

export const addDistributor = async (
  session: Session,
  distributor: DistributorFormInput
): Promise<{ status: string; data: Response<Distributor> }> => {
  const body = JSON.stringify(distributor);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/distributor`, {
    method: 'POST',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull add');
  }
  return response.json();
};

export const addCustomer = async (
  session: Session,
  customer: CustomerFormInput
): Promise<{ status: string; data: Response<Customer> }> => {
  const body = JSON.stringify(customer);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/customer`, {
    method: 'POST',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull add');
  }
  return response.json();
};

export const addSite = async (
  session: Session,
  site: SiteFormInput
): Promise<{ status: string; data: Response<Site> }> => {
  const body = JSON.stringify(site);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/site`, {
    method: 'POST',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull add');
  }
  return response.json();
};

export const addInstrument = async (
  session: Session,
  instrument: InstrumentFormInput
): Promise<{ status: string; data: Response<Instrument> }> => {
  const body = JSON.stringify(instrument);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/instrument`, {
    method: 'POST',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull add');
  }
  return response.json();
};

export const manageDistributor = async (
  session: Session,
  distributor: DistributorFormInput
): Promise<{ status: string; data: Response<Distributor> }> => {
  const body = JSON.stringify(distributor);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/distributor`, {
    method: 'PUT',
    headers: getAuthHeaders(session),
    body,
  });

  if (response.status !== 200) {
    throw new Error('unsuccessfull update');
  }
  return response.json();
};

export const manageCustomer = async (
  session: Session,
  customer: CustomerFormInput
): Promise<{ status: string; data: Response<Customer> }> => {
  const body = JSON.stringify(customer);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/customer`, {
    method: 'PUT',
    headers: getAuthHeaders(session),
    body,
  });

  if (response.status !== 200) {
    throw new Error('unsuccessfull update');
  }
  return response.json();
};

export const manageSite = async (
  session: Session,
  site: SiteFormInput
): Promise<{ status: string; data: Response<Site> }> => {
  const body = JSON.stringify(site);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/site`, {
    method: 'PUT',
    headers: getAuthHeaders(session),
    body,
  });

  if (response.status !== 200) {
    throw new Error('unsuccessfull update');
  }
  return response.json();
};

export const manageInstrument = async (
  session: Session,
  instrument: InstrumentFormInput
): Promise<{ status: string; data: Response<Instrument> }> => {
  const body = JSON.stringify(instrument);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/instrument`, {
    method: 'PUT',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull update');
  }
  return response.json();
};

export const disableDistributor = async (
  session: Session,
  distributorId: number
): Promise<{ status: string; data: Response<number> }> => {
  const bodyBase: OrgUnitDeleteInput = {
    id: distributorId,
  };
  const body = JSON.stringify(bodyBase);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/distributor`, {
    method: 'DELETE',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull delete');
  }
  return response.json();
};

export const disableCustomer = async (
  session: Session,
  customer: number
): Promise<{ status: string; data: Response<CustomerDeleteResponse> }> => {
  const bodyBase: OrgUnitDeleteInput = {
    id: customer,
  };
  const body = JSON.stringify(bodyBase);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/customer`, {
    method: 'DELETE',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull delete');
  }
  return response.json();
};

export const disableSite = async (
  session: Session,
  siteId: number
): Promise<{ status: string; data: Response<SiteDeleteResponse> }> => {
  const bodyBase: OrgUnitDeleteInput = {
    id: siteId,
  };
  const body = JSON.stringify(bodyBase);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/site`, {
    method: 'DELETE',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull delete');
  }
  return response.json();
};

export const disableInstrument = async (
  session: Session,
  serialNumber: string
): Promise<{ status: string; data: Response<InstrumentDeleteResponse> }> => {
  const bodyBase: InstrumentDeleteInput = {
    instrumentId: serialNumber,
  };
  const body = JSON.stringify(bodyBase);
  const response = await fetch(`${BACKEND_API_BASE_URL}/organization/instrument`, {
    method: 'DELETE',
    headers: getAuthHeaders(session),
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull delete');
  }
  return response.json();
};
