function RemoveIcon() {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25234 21.3406C1.41198 20.8209 0.890182 20.0633 0.686944 19.0679C0.483706 18.0725 0.658089 17.1286 1.21009 16.2361L8.39277 4.62259C8.05865 4.41595 7.85077 4.1127 7.76913 3.71284C7.68749 3.31299 7.7564 2.93563 7.97587 2.58077L8.77395 1.29039C8.99342 0.93553 9.30026 0.70533 9.69449 0.599785C10.0887 0.49424 10.4529 0.54479 10.787 0.751435L16.8619 4.50861C17.196 4.71525 17.4039 5.0185 17.4855 5.41836C17.5672 5.81822 17.4982 6.19557 17.2788 6.55043L16.4807 7.84082C16.2612 8.19567 15.9544 8.42587 15.5602 8.53142C15.1659 8.63696 14.8018 8.58641 14.4676 8.37977L7.28496 19.9932C6.73296 20.8858 5.96628 21.4634 4.98492 21.7261C4.00355 21.9889 3.09269 21.8604 2.25234 21.3406ZM8.68758 3.46695L15.3699 7.59984L16.5671 5.66426L9.8847 1.53137L8.68758 3.46695ZM2.85089 20.3728C3.36726 20.6922 4.03099 20.8675 4.77863 20.6508C5.50972 20.4389 5.92913 20.0467 6.32817 19.4015L10.2643 13.139L10.8629 12.1712L11.4615 11.2035L12.06 10.2357L12.6586 9.26788L13.5564 7.81619L9.304 5.18617L2.12132 16.7996C1.73559 17.4233 1.61394 18.084 1.75638 18.7816C1.89882 19.4792 2.26366 20.0096 2.85089 20.3728Z"
        fill="#3E5D74"
      />
    </svg>
  );
}

export default RemoveIcon;
