import './App.css';

import { authenticate } from 'api/Api';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { store } from 'redux/store';
import DecoupledView from 'views/decoupled/DecoupledView';
import LoginView from 'views/login/LoginView';

import AppContent from './AppContent';

function App() {
  const [hasSession, setHasSession] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [loadingSession, setWaitingForSession] = useState(false);
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      const state = store.getState();

      const now = dayjs().unix();
      const limit = state.headers.timestamp;

      const loggedIn = state.headers.accessToken !== '' && now < limit && limit !== 0;
      const urlAuthCode = new URLSearchParams(window.location.search).get('code') ?? '';

      if (loggedIn) {
        setHasSession(true);
      } else if (urlAuthCode) {
        authenticate(urlAuthCode);
        setWaitingForSession(true);
      }

      store.subscribe(() => {
        // need to run getState again to get updated state
        const loggedInStatus = store.getState().headers.accessToken !== '';

        setHasSession(loggedInStatus);
        if (loggedInStatus) setWaitingForSession(false);
      });
    }
  }, [hasSession]);

  useEffect(() => {
    setIsPDF(location.pathname.includes('/pdf'));
  }, [location.pathname]);

  return (
    <Provider store={store}>
      {isPDF && <DecoupledView />}
      {hasSession && !isPDF && <AppContent />}
      {loadingSession && <LoadingSpinner />}
      {!hasSession && !loadingSession && !isPDF && <LoginView />}
    </Provider>
  );
}

export default App;
