import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

export const DropdownIcon = styled.button`
  height: 3em;
  width: 3em;
  background: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  :hover {
    transform: scale(1.15);
  }
`;

export const Dropdown = styled.div<{ toggled: boolean }>`
  display: ${({ toggled }) => (toggled ? 'block' : 'none')};
  position: absolute;
  z-index: 999;
  min-height: 2em;
  margin-top: 1em;
  background: ${() => useTheme().font.color.white};
  box-shadow: 1px 3px 10px ${() => useTheme().shadow.secondary};
  border-radius: 3px;
`;

export const DropdownBoxBinary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const DropdownBoxTernary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const DropdownOption = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${() => useTheme().font.color.white};
  border: none;
  border-bottom: ${({ theme: { background } }) => `1px solid ${background.medium}`};
  padding: 1em;
  border-radius: none;
  transition: 50ms ease-in-out;
  input {
    margin-right: 1em;
  }
  :hover {
    background: ${() => useTheme().background.medium};
    opacity: 1;
    cursor: pointer;
  }
`;

export const DropdownColumn = styled.div<{ dropdownWidth?: number }>`
  width: ${({ dropdownWidth }) => (dropdownWidth ? `${dropdownWidth}em` : '9em')};
  border-right: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  :last-child {
    border: none;
  }
  display: inline-block;
  ${DropdownOption} {
    border-bottom: none;
  }
`;

export const DropdownTitle = styled(DropdownOption)`
  justify-content: space-between;
  background: white;
  :hover {
    background: white;
    cursor: auto;
  }
`;

export const DropdownContainer = styled.div`
  margin-right: 5em;
`;
