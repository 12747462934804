import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

interface TitleWrapperProps {
  color?: string;
  bold?: boolean;
  size?: string;
}

const TitleWrapper = styled.div<TitleWrapperProps>`
  display: grid;
  grid-template-rows: auto;
  margin: 1em 0;
  color: ${({ color }) => color || useTheme().font.color.black};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: ${({ size }) => size};
`;

export default TitleWrapper;
