import { UploadHistoryEntry } from '@dewire/models/definitions/api-response/upload-history-entry';
import { getFileHistory, getUploadUrl } from 'redux/slices/files.slice';
import { store } from 'redux/store';

interface FileUploadPayload {
  data: {
    result: { url: string };
  };
}
interface FileHistoryPayload {
  data: {
    result: UploadHistoryEntry[];
  };
}

export async function getNewFileUploadUrl(
  nameOfFile: string,
  isAssay: boolean,
  onFetchedUrl: (url: string) => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getUploadUrl({ fileName: nameOfFile, isAssay, session: headersState }));
  const payload = response.payload as FileUploadPayload;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedUrl(payload.data.result.url);
}

export async function getAllFiles(onFetchedFiles: (files: UploadHistoryEntry[]) => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getFileHistory(headersState));
  const payload = response.payload as FileHistoryPayload;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedFiles(payload.data.result);
}
