import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { TextColor } from 'interfaces/common';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import bouleSmallLogo from '../../assets/images/logoBouleSmall.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import knightecLogo from '../../assets/images/logoKnightec.svg';

interface IdentityProviderProps {
  name: string;
  url: string;
}

// const LogoWrapper = styled.div`
//   margin: 0.5em;
// `;

const RootContainer = styled.div`
  display: flex;
  grid-gap: 2em;
  align-items: center;
  justify-content: center;
  width: 10em;
  margin: 2em 1em;
  height: 3em;
  border-radius: 2em;
  background-color: ${({ theme }) => theme.background.dark};
  cursor: pointer;
`;

function IdentityProvider({ name, url }: IdentityProviderProps) {
  return (
    <RootContainer
      onClick={() => {
        document.location.href = url;
      }}
    >
      {/* <LogoWrapper>
        {name === 'Knightec' ? (
          <img height="20px" width="20px" src={knightecLogo} alt="" />
        ) : (
          <img height="20px" width="20px" src={bouleSmallLogo} alt="" />
        )}
      </LogoWrapper> */}
      <TextWrapper size="small" color={TextColor.White}>
        {name}
      </TextWrapper>
    </RootContainer>
  );
}

export default IdentityProvider;
