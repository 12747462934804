import TextField from '@mui/material/TextField';
import { useTheme } from 'app/AppStyling';
import useTextFieldStyles from 'components/styled-components/mui-styling/TextFieldStyling';
import { useState } from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}px` : '90px')};
`;

interface TextInputFieldProps {
  label: string;
  filled: boolean;
  search?: boolean;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  helper?: string;
  value: string;
  width?: number;
  disabled?: boolean;
  containerHeight?: number;
  onChangeCallback: (textInput: string) => void;
}

const DefaultProps = {
  search: false,
  multiline: false,
  rows: 1,
  error: false,
  helper: '',
  width: 18,
  disabled: false,
  containerHeight: 90,
};

function TextInputField({
  label,
  filled,
  search,
  multiline,
  rows,
  error,
  value,
  helper,
  width,
  disabled,
  containerHeight,
  onChangeCallback,
}: TextInputFieldProps) {
  const classes = useTextFieldStyles({ background: useTheme().background.light });
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <OuterContainer height={containerHeight}>
      <TextField
        disabled={disabled}
        type={search ? 'search' : 'text'}
        id="textfield-basic"
        style={{ margin: '1em', width: `${width}em` }}
        label={label}
        error={error && !focused}
        variant={filled ? 'filled' : undefined}
        multiline={multiline}
        rows={rows}
        value={value}
        helperText={error && !focused ? helper : ''}
        onChange={(e) => onChangeCallback(e.target.value)}
        InputProps={{
          className: error && !focused ? classes.textFieldError : classes.textField,
        }}
        InputLabelProps={{ className: classes.textFieldLabel }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </OuterContainer>
  );
}

TextInputField.defaultProps = DefaultProps;

export default TextInputField;
