import { useTheme } from 'app/AppStyling';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div<{ left: number }>`
  cursor: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  padding: 2em 2em;
  min-width: 22em;
  min-height: 10em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  gap: 1em;
  right: 0;
  text-align: left;
  z-index: 99;
  left: ${({ left }) => `${left}em`};
  top: 2em;
`;
const Container = styled.div`
  position: relative;
`;
const IconContainer = styled.div<{ isTexted?: boolean }>`
  display: flex;
  gap: 0.5em;
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid ${({ isTexted }) => (isTexted ? useTheme().colors.danger.iconDetails : 'transparent')};
  }
`;

interface InfoIconModalProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  text?: React.ReactNode | string;
  leftAlign?: number;
}

const DefaultProps = {
  text: '',
  leftAlign: -10,
};

function InfoIconModal({ children, icon, text, leftAlign = -10 }: InfoIconModalProps) {
  const dropdownRef = useRef<any>(null);
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    function clickOutsideDropdownHandler(e: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target) && displayModal) {
        setDisplayModal(false);
      }
    }

    document.addEventListener('mousedown', clickOutsideDropdownHandler);
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropdownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef, displayModal]);

  return (
    <Container ref={dropdownRef}>
      <IconContainer onClick={() => setDisplayModal((toggled) => !toggled)} isTexted={text !== ''}>
        {icon}
        {text}
      </IconContainer>
      {displayModal && <ModalContainer left={leftAlign}>{children}</ModalContainer>}
    </Container>
  );
}

InfoIconModal.defaultProps = DefaultProps;

export default InfoIconModal;
