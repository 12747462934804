import { useTheme } from 'app/AppStyling';
import ButtonSet from 'components/sets/ButtonSet';
import CardContainer from 'components/styled-components/containers/CardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import styled from 'styled-components';

import saveIcon from '../../assets/icons/save.svg';

const CardBanner = styled.div`
  background-color: ${() => useTheme().background.blue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 1em 1em 1em;
  border-radius: 0.5em 0.5em 0em 0em;
`;
const AlertTitle = styled(TitleWrapper)`
  font-weight: bold;
  margin: 1em;
`;
const WarningText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em 10em;
`;

interface SavePermissionModalProps {
  discardChanges: () => void;
  onSave: () => void;
}

function SavePermissionModal({ discardChanges, onSave }: SavePermissionModalProps) {
  return (
    <DimmedContainer>
      <CardContainer>
        <CardBanner>
          <img src={saveIcon} alt="save" />
          <AlertTitle color={useTheme().colors.blue}>Save changes?</AlertTitle>
        </CardBanner>
        <>
          <WarningText>
            <p>You are about to close role selection with unsaved changes.</p>
            <p>Do you want to save changes before closing?</p>
          </WarningText>
          <ButtonSet
            primary="Save changes"
            secondary="Proceed without saving"
            primaryClickCallback={() => onSave()}
            secondaryClickCallback={() => discardChanges()}
          />
        </>
      </CardContainer>
    </DimmedContainer>
  );
}

export default SavePermissionModal;
