import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { DefaultTheme, useTheme } from 'app/AppStyling';
import useTextFieldStyles from 'components/styled-components/mui-styling/TextFieldStyling';
import { FieldInput } from 'interfaces/field-input';
import { useState } from 'react';
import styled from 'styled-components';

import CheckboxInput from './CheckboxInput';

const ConfirmButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  margin: 0.5em 0.8em 0 0.8em;
  padding: 0.5em;
  background: ${() => useTheme().background.blue};
  cursor: pointer;
  border-radius: 4px;
  color: ${() => useTheme().colors.blue};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.65' : '1')};
  filter: ${({ disabled }) => (disabled ? 'alpha(opacity=65)' : 'none')};
  &:hover {
    color: ${() => useTheme().colors.primary};
  }
`;
const ApplyButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
interface DropdownInputProps<T extends string | number> {
  label: string;
  value: FieldInput<T>;
  valueArray: FieldInput<T>[];
  checkbox: boolean;
  disabled?: boolean;
  confirmButton?: boolean;
  disabledConfirm?: boolean;
  width?: number;
  error?: boolean;
  helper?: string;
  backgroundColor?: string;
  onChangeCallback: (id: T | undefined) => void;
  confirmCallback?: () => void;
  closeCallback?: () => void;
}

const DefaultProps = {
  disabled: false,
  confirmButton: false,
  disabledConfirm: false,
  width: 18,
  error: false,
  helper: '',
  backgroundColor: DefaultTheme.background.light,
  confirmCallback: () => {},
  closeCallback: () => {},
};

function DropdownInput<T extends string | number>({
  label,
  value,
  valueArray,
  checkbox,
  disabled,
  confirmButton,
  disabledConfirm,
  width,
  error,
  helper,
  backgroundColor,
  onChangeCallback,
  confirmCallback,
  closeCallback,
}: DropdownInputProps<T>) {
  const classes = useTextFieldStyles({ background: backgroundColor ?? DefaultTheme.background.light });

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  function handleChangeByArray(e: SelectChangeEvent<string[]>) {
    const eventValue = e.target.value.slice(1);
    const newValue = valueArray.find((inputValue) => inputValue.label.toLowerCase() === eventValue[0].toLowerCase());
    if (newValue) onChangeCallback(newValue.id);
    else onChangeCallback('' as T);
  }

  function handleChangeByString(e: SelectChangeEvent<string[]>) {
    const eventValue = e.target.value.toString();
    const newValue = valueArray.find((inputValue) => inputValue.label.toLowerCase() === eventValue.toLowerCase());
    if (newValue) {
      onChangeCallback(newValue.id);
    } else {
      onChangeCallback('' as T);
    }
  }

  function handleChange(e: SelectChangeEvent<string[]>) {
    if (e.target.value.length > 1 && confirmButton) handleChangeByArray(e);
    else handleChangeByString(e);
  }

  function onApplyButtonClick() {
    if (confirmCallback) confirmCallback();
  }

  return (
    <FormControl
      disabled={disabled}
      variant="filled"
      sx={{
        m: 1,
        margin: '0em',
        width: `${width}em`,
        background: backgroundColor,
      }}
      size={value.label === '' ? 'medium' : 'small'}
      error={error && !focused}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={[value.label] || ''}
        multiple={confirmButton}
        onChange={(e) => {
          if (e.target.value.length > 0) handleChange(e);
        }}
        renderValue={(selected) => <ListItemText primary={selected} />}
        onClose={() => {
          if (closeCallback) closeCallback();
        }}
        className={error && !focused ? classes.textFieldError : classes.textField}
      >
        {valueArray.map((inputValue) => (
          <MenuItem key={inputValue.id === undefined ? -1 : inputValue.id} value={inputValue.label}>
            {checkbox ? (
              <CheckboxInput rounded checked={inputValue.id === value.id} text={inputValue.label} />
            ) : (
              <ListItemText primary={inputValue.label} />
            )}
          </MenuItem>
        ))}
        {confirmButton && (
          <ConfirmButtonContainer disabled={disabledConfirm}>
            <ApplyButton onClick={() => onApplyButtonClick()} onKeyDown={() => {}} role="button" tabIndex={0}>
              Apply
            </ApplyButton>
          </ConfirmButtonContainer>
        )}
      </Select>
      {error && !focused && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
}

DropdownInput.defaultProps = DefaultProps;

export default DropdownInput;
