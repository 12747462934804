import styled from 'styled-components';

const Header3 = styled.h3`
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  .hover {
    text-decoration: none;
  }
  .visited {
    text-decoration: none;
  }
  .focused {
    text-decoration: none;
  }
  .active {
    text-decoration: none;
  }
`;

export default Header3;
