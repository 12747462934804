import { Customer } from '@dewire/models/definitions/api-response/customer';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import PenIcon from 'assets/icons/PenIcon';
import InfoIconModal from 'components/modals/InfoIconModal';
import InTableButton from 'components/styled-components/buttons/InTableButton';
import TableRow from 'components/styled-components/table/TableRow';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { useAppSelector } from 'redux/hooks';
import styled, { useTheme } from 'styled-components';

const RowItem = styled.div<{ icon?: boolean }>`
  white-space: nowrap;
  overflow: ${({ icon }) => (icon ? '' : 'hidden')};
  text-overflow: ellipsis;
  max-width: 16em;
  ${() => bpTheme.breakpoints.down('lg')} {
    max-width: 12em;
  }
`;
const IconContainer = styled.div`
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid ${() => useTheme().colors.primary};
  }
`;
const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 8em 4em;
`;

interface CustomerRowProps {
  customer: Customer;
  sitesCallback: () => void;
  instrumentsCallback: () => void;
  manageCustomerCallback: () => void;
}

function CustomerRow({ customer, sitesCallback, instrumentsCallback, manageCustomerCallback }: CustomerRowProps) {
  const customersAccess = useAppSelector((state) => state.headers.accesses.organization.customers);

  return (
    <TableRow gridExpression="20% 20% 20% 20% 18% 6%">
      <RowItem className="customerName">{customer.name}</RowItem>
      <RowItem>{customer.distributorName}</RowItem>
      <RowItem>{customer.country}</RowItem>
      <InTableButton onClick={() => sitesCallback()}>
        {customer.numOfSites}
        <ExpandArrowIcon open={false} color="black" />
      </InTableButton>
      <InTableButton onClick={() => instrumentsCallback()}>
        {customer.numOfInstruments}
        <ExpandArrowIcon open={false} color="black" />
      </InTableButton>
      {customersAccess.view && !customersAccess.edit ? (
        <RowItem icon>
          <InfoIconModal icon={<InfoIcon />}>
            <InfoItem>
              <h4>Address: </h4>
              {customer.formData.address ? customer.formData.address : 'No address'}
            </InfoItem>
            <InfoItem>
              <h4>City: </h4>
              {customer.formData.city ? customer.formData.city : 'No city'}
            </InfoItem>
            <InfoItem>
              <h4>Postal code: </h4>
              {customer.formData.postalCode ? customer.formData.postalCode : 'No postal code'}
            </InfoItem>
            <InfoItem>
              <h4>E-mail: </h4>
              {customer.formData.email ? customer.formData.email : 'No email'}
            </InfoItem>
            <InfoItem>
              <h4>Phone: </h4>
              {customer.formData.phoneNumber ? customer.formData.phoneNumber : 'No phone number'}
            </InfoItem>
          </InfoIconModal>
        </RowItem>
      ) : (
        <RowItem onClick={() => manageCustomerCallback()}>
          <IconContainer>
            <PenIcon />
          </IconContainer>
        </RowItem>
      )}
    </TableRow>
  );
}

export default CustomerRow;
