import { useTheme } from 'app/AppStyling';
import { Paths as paths } from 'interfaces/common';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import PDFView from 'views/PDF/PDFView';

const ViewContainer = styled.div`
  background: ${() => useTheme().background.light};
  min-height: 90vh;
  padding: 0.5em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function DecoupledView() {
  return (
    <ViewContainer>
      <Routes>
        <Route path={paths.PDFUrl} element={<PDFView />} />
      </Routes>
    </ViewContainer>
  );
}

export default DecoupledView;
