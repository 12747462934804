import { DefaultTheme } from 'app/AppStyling';
import styled from 'styled-components';

interface ExpandArrowIconProps {
  open?: boolean;
  color?: string;
}

const ChevronShape = styled.svg<ExpandArrowIconProps>`
  ${({ open }) => {
    if (open) return { transform: 'rotate(0deg)' };
    return { transform: 'rotate(-90deg)' };
  }}
  transition: transform .5s;
`;

function ExpandArrowIcon({ open, color }: ExpandArrowIconProps) {
  return (
    <ChevronShape open={open} width="12" height="6" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L7 6L1 1" stroke={color} />
    </ChevronShape>
  );
}

ExpandArrowIcon.defaultProps = {
  open: false,
  color: DefaultTheme.icon.light,
};

export default ExpandArrowIcon;
