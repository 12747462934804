import { BouleUser } from '@dewire/models/definitions/api-response/boule-user';
import UserCard from 'components/cards/UserCard';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import FilterModal from 'components/modals/FilterModal';
import Paginator from 'components/paginator/Paginator';
import FilterGroup from 'components/selection/filter/FilterGroup';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import SearchField from 'components/styled-components/selection/SearchField';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllUsers } from 'helpers/users/getters';
import { LoadingState, Roles, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';

const UsersViewRoot = styled.div`
  display: contents;
`;
const SearchBarContainer = styled.div`
  width: 40em;
`;
const UserListContainer = styled.div`
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, 25em);
  justify-content: space-between;
`;
const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const PaginatorContainer = styled.div`
  margin: -2em 0 2em 0;
`;

function UsersView() {
  const usersState = useAppSelector((state) => state.users);

  const bouleUsers = usersState.users.content;
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<string[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<BouleUser[]>([]);
  const [sortedUsers, setSortedUsers] = useState<BouleUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [page, setPage] = useState(1);
  const pageLimit = 12;
  const filterOptions = Object.values(Roles);
  const filterActive = filter.length !== 0;

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const navigateToUserSettingsView = (_user: BouleUser) => {
    window.location.href = `/users/${_user.id}`;
  };

  const handleFilter = (option: string) => {
    const tempFilters = [...filter];
    if (filter.includes(option)) {
      setFilter(tempFilters.filter((element) => element !== option));
    } else {
      tempFilters.push(option);
      setFilter(tempFilters);
    }
  };

  const handleFilterClear = () => {
    setFilter([]);
  };

  useEffect(() => {
    const status = usersState.users.loadingStatus;
    setLoadingUsers(status === LoadingState.Loading);
    if (status === LoadingState.Idle) {
      getAllUsers();
    } else if (bouleUsers.length === 0 && status !== LoadingState.Loading) {
      provideSnackbar(Status.Info, 'Status', 'No users currently exist.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersState.users.loadingStatus]);

  useEffect(() => {
    setSortedUsers(lodash.sortBy(bouleUsers, (user) => (user.name === '' ? 1 : 0), 'name'));
  }, [bouleUsers]);

  useEffect(() => {
    if (filter.length > 0) {
      setFilteredUsers([
        ...sortedUsers.filter((user) => lodash.difference(user.roles, filter).length === 0 && user.roles.length > 0),
      ]);
    } else {
      setFilteredUsers(sortedUsers);
    }
    handlePagination(1);
  }, [sortedUsers, filter]);

  const availableUsers = filteredUsers
    .filter((user) => !search.length || (search.length && user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1))
    .map((user) => (
      <UserCard
        key={user.id}
        userName={user.name}
        userRoles={user.roles}
        userPhone={user.phone}
        userEmail={user.email}
        setUserCallback={() => navigateToUserSettingsView(user)}
      />
    ));

  return (
    <UsersViewRoot>
      <ViewContainer columns={0} rows={3}>
        <ToolbarWrapper>
          <SearchBarContainer>
            <SearchField
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              iconUrl={magnyfyingGlass}
            />
          </SearchBarContainer>
          <FilterModal isFiltering={filterActive} clearCallback={() => handleFilterClear()}>
            <FilterGroup
              groupWidth={13.2}
              filterOptions={filterOptions}
              filterArray={filter}
              onFilterCallback={(filterValue) => handleFilter(filterValue)}
            />
          </FilterModal>
        </ToolbarWrapper>
        {loadingUsers ? (
          <LoadingSpinner />
        ) : (
          <UserListContainer id="userListContainer">
            {availableUsers.slice(page * pageLimit - pageLimit, page * pageLimit)}
          </UserListContainer>
        )}
      </ViewContainer>
      <PaginatorContainer>
        {availableUsers.length > pageLimit && !loadingUsers && (
          <Paginator
            currentPage={page}
            maxPages={Math.ceil(availableUsers.length / pageLimit)}
            paginationCallback={(newPage: number) => handlePagination(newPage)}
          />
        )}
      </PaginatorContainer>
    </UsersViewRoot>
  );
}

export default UsersView;
