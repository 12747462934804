import CycleInfo from '@dewire/models/definitions/api-response/cycle-info';
import CycleInfoBox from 'components/styled-components/cycles/CycleInfoBox';
import CycleItemBox from 'components/styled-components/cycles/CycleItemBox';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { Status } from 'interfaces/common';
import lodash from 'lodash';
import CycleItemRow from 'views/single-instrument/cycles/CycleItemRow';

interface CycleItemCardProps {
  cycleInfo: CycleInfo;
}

function CycleItemCard({ cycleInfo }: CycleItemCardProps) {
  const startTime = dayjs(cycleInfo.startTime);
  return (
    <CycleItemBox key={`${cycleInfo.cycleName}-${startTime.format('YYYY-MM-DD')}`}>
      <CycleInfoBox isError={cycleInfo.error !== undefined}>
        <CycleItemRow title={getTranslation('Cycle')} value={cycleInfo.cycleName} />
        {cycleInfo.powerOnType && (
          <CycleItemRow title={getTranslation('Power on type')} value={cycleInfo.powerOnType} />
        )}
        {cycleInfo.error && (
          <CycleItemRow title={getTranslation(lodash.capitalize(Status.Error))} value={cycleInfo.error.toString()} />
        )}
        <CycleItemRow title={getTranslation('Start Time')} value={startTime.format('HH:mm')} />
      </CycleInfoBox>
    </CycleItemBox>
  );
}

export default CycleItemCard;
