/* eslint-disable react/jsx-props-no-spreading */
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTheme } from 'app/AppStyling';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import locateEnvironment from 'helpers/environment/locate-environment';
import GetErrorMessage, { noUploadErrorStatus, UploadErrorStatus } from 'helpers/error-handling/get-error-message';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getNewFileUploadUrl } from 'helpers/file-upload/getters';
import getTranslation from 'helpers/translation/get-translation';
import { Environments, LoadingState, Status, TextColor } from 'interfaces/common';
import lodash from 'lodash';
import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import uploadIcon from '../../assets/icons/upload.svg';
import ProgressRow from './ProgressRow';

const DropFileBox = styled.div`
  height: 20em;
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.lineColor};
  background-color: ${({ theme }) => theme.background.light};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const UploadProgressList = styled.div`
  height: 20em;
  width: 38em;
  border-bottom: ${({ theme }) => theme.lineWidthBold} solid ${({ theme }) => theme.lineColor};
`;
const ProgressList = styled.div`
  height: 18em;
  width: 31em;
  overflow: scroll;
`;
const ProgressHeader = styled.div`
  height: 2em;
  padding: 0.5em;
  background-color: ${({ theme }) => theme.background.light};
  border-bottom: ${({ theme }) => theme.lineWidthBold} solid ${({ theme }) => theme.lineColor};
`;
const UploadContainer = styled.div`
  display: flex;
  gap: 3em;
  background-color: ${() => useTheme().font.color.white};
  padding: 2em;
`;
const CenteredDiv = styled.div`
  text-align: center;
`;
const ButtonLikeDiv = styled.div`
  display: inline-block;
  background-color: ${() => useTheme().background.blue};
  color: ${() => useTheme().colors.blue};
  width: 8em;
  height: 3em;
  font-weight: bold;
  line-height: 3em;
  margin: 1em;
`;
const Icon = styled.img`
  margin: 1em;
`;
const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 20em;
  width: 100%;
  gap: 0.5em;
`;

enum FileUploadTypes {
  Assay = 'assay',
  QualityControl = 'qc',
}

interface FileWithProgress {
  file: File;
  status: LoadingState;
}

const containsDuplicates = (newFiles: File[], files: FileWithProgress[]) => {
  const foundFiles: File[] = [];
  newFiles.forEach((newFile) => {
    if (files.find((file) => file.file.name === newFile.name)) foundFiles.push(newFile);
  });
  return foundFiles;
};

const updateFileStatus = (
  files: FileWithProgress[],
  fileToUpdate: File,
  newStatus: LoadingState
): FileWithProgress[] => {
  const updatedFiles = files.map((file) => {
    if (file.file.name === fileToUpdate.name) {
      return {
        ...file,
        status: newStatus,
      };
    }
    return file;
  });

  return updatedFiles;
};

const shouldUpdateHistory = (newState: FileWithProgress[], oldState: FileWithProgress[]): boolean => {
  let shouldUpdate = false;
  if (newState.length > oldState.length) {
    shouldUpdate = true;
  }

  if (newState.length > 0) {
    const newStateNames = new Set(newState.map((file) => file.file.name));
    const oldStateNames = new Set(oldState.map((file) => file.file.name));

    newStateNames.forEach((name) => {
      if (!oldStateNames.has(name)) {
        shouldUpdate = true;
      }
    });
  }

  return shouldUpdate;
};

function FileUploadContainer({ loadHistoryCallback }: { loadHistoryCallback: () => void }) {
  const headersState = useAppSelector((state) => state.headers);

  const hasAssayUploadAccess = headersState.accesses.assayFileUpload;
  const hasQcUploadAccess = headersState.accesses.instrumentFileUpload;
  const [files, setFiles] = useState<FileWithProgress[]>([]);
  const [error, setError] = useState<UploadErrorStatus>(noUploadErrorStatus);
  const [selectedUploadType, setSelectedUploadType] = useState<FileUploadTypes>(
    hasAssayUploadAccess && !hasQcUploadAccess ? FileUploadTypes.Assay : FileUploadTypes.QualityControl
  );

  const removeFileFromList = (fileName: string) => {
    setFiles(files.filter((file) => file.file.name !== fileName));
  };

  const uploadFile = async (file: File) => {
    const onFetchedUrl = async (response: string) => {
      const headers = new Headers(
        selectedUploadType === FileUploadTypes.Assay
          ? { 'Content-Type': 'application/vnd.ms-excel' }
          : { 'Content-Type': 'text/xml' }
      );

      const res = await fetch(response, {
        method: 'PUT',
        body: file,
        headers,
      });

      if (res.status === 200) setFiles((prevFiles) => updateFileStatus(prevFiles, file, LoadingState.Succeeded));
      else setFiles((prevFiles) => updateFileStatus(prevFiles, file, LoadingState.Failed));
    };

    getNewFileUploadUrl(file.name, selectedUploadType === FileUploadTypes.Assay, onFetchedUrl, () => {
      provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to upload file.');
    });
  };

  const onDrop = useCallback(
    async (newFiles: File[], rejections: FileRejection[]) => {
      const duplicates = containsDuplicates(newFiles, files);

      if (duplicates.length > 0) {
        provideSnackbar(
          Status.Error,
          'Duplicate file(s)',
          `Following file(s) ignored as they are already being uploaded: ${duplicates
            .map((duplicate) => `\n - "${duplicate.name}"`)
            .join('')}`
        );
      }

      const nonDuplicates = newFiles
        .filter((newFile) => duplicates.findIndex((duplicate) => duplicate.name === newFile.name) === -1)
        .map((file) => ({ file, status: LoadingState.Loading }));
      const newState = [...nonDuplicates, ...files];
      setFiles((prevState) => [...nonDuplicates, ...prevState]);

      await Promise.all(
        nonDuplicates.map(async (newFile) => {
          await uploadFile(newFile.file);
        })
      );

      if (shouldUpdateHistory(newState, files)) setTimeout(() => loadHistoryCallback(), 6000);

      setError(GetErrorMessage(rejections));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headersState, selectedUploadType, files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/plain': ['.txt'],
      'application/xml': ['.xml'],
      'application/vnd.ms-excel': ['.xlsx'],
    },
    maxFiles: 10,
    maxSize: 5000000,
    onDrop,
  });

  return (
    <UploadContainer>
      <UploadProgressList>
        <ProgressHeader>
          <TextWrapper bold>{getTranslation('Uploading')}</TextWrapper>
        </ProgressHeader>
        <ProgressList>
          {files.map(({ file, status }) => (
            <ProgressRow
              name={file.name}
              status={status}
              deleteCallback={() => removeFileFromList(file.name)}
              key={file.name}
            />
          ))}
        </ProgressList>
      </UploadProgressList>
      <FileUploadWrapper>
        {locateEnvironment([Environments.Dev, Environments.Cont, Environments.Test, Environments.Demo]) &&
          hasAssayUploadAccess &&
          hasQcUploadAccess && (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event) =>
                  setSelectedUploadType(
                    (event.target as HTMLInputElement).value === 'assay'
                      ? FileUploadTypes.Assay
                      : FileUploadTypes.QualityControl
                  )
                }
                value={selectedUploadType}
              >
                <FormControlLabel
                  value={FileUploadTypes.QualityControl}
                  control={<Radio />}
                  label={getTranslation('Quality control')}
                />
                <FormControlLabel
                  value={FileUploadTypes.Assay}
                  control={<Radio />}
                  label={getTranslation('Assay data')}
                />
              </RadioGroup>
            </FormControl>
          )}
        <DropFileBox {...getRootProps()}>
          <input {...getInputProps()} />
          <CenteredDiv>
            <Icon src={uploadIcon} alt="upload-icon" />
            <TextWrapper bold>Drag and drop files here</TextWrapper>
            <TextWrapper>or</TextWrapper>
            <ButtonLikeDiv>Browse files</ButtonLikeDiv>
            {error && (
              <>
                <TextWrapper bold color={TextColor.Danger}>
                  {error.uploadErrorHeader}
                </TextWrapper>
                <TextWrapper color={TextColor.Danger}>{error.uploadErrorMessage}</TextWrapper>
              </>
            )}
          </CenteredDiv>
        </DropFileBox>
      </FileUploadWrapper>
    </UploadContainer>
  );
}

export default FileUploadContainer;
