import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import PenIcon from 'assets/icons/PenIcon';
import InfoIconModal from 'components/modals/InfoIconModal';
import InTableButton from 'components/styled-components/buttons/InTableButton';
import TableRow from 'components/styled-components/table/TableRow';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { useAppSelector } from 'redux/hooks';
import styled, { useTheme } from 'styled-components';

const RowItem = styled.div<{ icon?: boolean }>`
  white-space: nowrap;
  overflow: ${({ icon }) => (icon ? '' : 'hidden')};
  text-overflow: ellipsis;
  max-width: 15em;
  ${() => bpTheme.breakpoints.down('lg')} {
    max-width: 10em;
  }
`;
const OrganizationTableButton = styled(InTableButton)`
  max-lines: 1;
`;
const IconContainer = styled.div`
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid ${() => useTheme().colors.primary};
  }
`;
const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 8em 4em;
`;

interface DistributorRowProps {
  distributor: Distributor;
  customersCallback: () => void;
  instrumentsCallback: () => void;
  manageDistributorCallback: () => void;
}

function DistributorRow({
  distributor,
  customersCallback,
  instrumentsCallback,
  manageDistributorCallback,
}: DistributorRowProps) {
  const distributorsAccess = useAppSelector((state) => state.headers.accesses.organization.distributors);

  return (
    <TableRow className="distributorTable" gridExpression="20% 20% 20% 20% 18% 5%">
      <RowItem className="distributorName">{distributor.name}</RowItem>
      <RowItem className="distributorMarketName">{distributor.marketName}</RowItem>
      <RowItem>{distributor.country}</RowItem>
      <OrganizationTableButton onClick={() => customersCallback()}>
        {distributor.numOfCustomers}
        <ExpandArrowIcon open={false} color="black" />
      </OrganizationTableButton>
      <InTableButton onClick={() => instrumentsCallback()}>
        {distributor.numOfInstruments}
        <ExpandArrowIcon open={false} color="black" />
      </InTableButton>
      {distributorsAccess.view && !distributorsAccess.edit ? (
        <RowItem icon>
          <InfoIconModal icon={<InfoIcon />}>
            <InfoItem>
              <h4>Address: </h4>
              {distributor.formData.address ? distributor.formData.address : 'No address'}
            </InfoItem>
            <InfoItem>
              <h4>City: </h4>
              {distributor.formData.city ? distributor.formData.city : 'No city'}
            </InfoItem>
            <InfoItem>
              <h4>Postal code: </h4>
              {distributor.formData.postalCode ? distributor.formData.postalCode : 'No postal code'}
            </InfoItem>
            <InfoItem>
              <h4>E-mail: </h4>
              {distributor.formData.email ? distributor.formData.email : 'No email'}
            </InfoItem>
            <InfoItem>
              <h4>Phone: </h4>
              {distributor.formData.phoneNumber ? distributor.formData.phoneNumber : 'No phone number'}
            </InfoItem>
          </InfoIconModal>
        </RowItem>
      ) : (
        <RowItem onClick={() => manageDistributorCallback()}>
          <IconContainer>
            <PenIcon />
          </IconContainer>
        </RowItem>
      )}
    </TableRow>
  );
}

export default DistributorRow;
