import { useTheme } from 'app/AppStyling';
import BouleIcon from 'assets/icons/BouleIcon';
import IdentityProvider from 'components/identity-provider/IdentityProvider';
import styled from 'styled-components';

import { LOGIN_HOSTED_UI_URL } from '../../config/config';

const LoginContainer = styled.div`
  min-height: 100vh;
  max-width: 120em;
  margin: auto;
  background-color: ${() => useTheme().font.color.white};
`;
const IDPContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5em auto;
  width: 18em;
  min-height: 18em;
  border-radius: 0.5em;
  background-color: ${() => useTheme().background.light}; // was #f0f6ff
`;
const InfoWrapper = styled.div`
  margin-top: 4em;
`;
const ProviderWrapper = styled.div`
  margin: 1em;
`;

function LoginView() {
  return (
    <LoginContainer>
      <IDPContainer>
        <InfoWrapper>
          <BouleIcon color={useTheme().background.dark} />
        </InfoWrapper>
        <ProviderWrapper>
          <IdentityProvider name="Sign in" url={LOGIN_HOSTED_UI_URL} />
        </ProviderWrapper>
      </IDPContainer>
    </LoginContainer>
  );
}

export default LoginView;
