import { useTheme } from 'app/AppStyling';
import { useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

interface SimpleGraphProps {
  data: { level?: string; value: number; date: { month: string; year: number } }[];
  pdf?: boolean;
}

const DefaultProps = {
  pdf: false,
};

function SimpleGraph({ data, pdf }: SimpleGraphProps) {
  const [height, setHeight] = useState(230);
  const [width, setWidth] = useState(570);
  const [horizontalPoints, sethorizontalPoints] = useState([52, 147]);
  const appTheme = useTheme();
  const yRange = [-4, 4];

  useEffect(() => {
    if (pdf) {
      setHeight(240);
      setWidth(343);
      sethorizontalPoints([55, 155]);
    }
  }, [pdf]);

  return (
    <LineChart width={width} height={height} data={data} style={{ marginTop: 10, marginRight: 10, marginLeft: -30 }}>
      <CartesianGrid strokeDasharray="3 3" horizontalPoints={horizontalPoints} />
      <XAxis dataKey="date.month" />
      <YAxis domain={yRange} allowDataOverflow tickCount={9} interval={0} />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="value"
        stroke={appTheme.colors.primary}
        strokeWidth={2}
        activeDot={{ r: 6 }}
        name="SDI"
      />
    </LineChart>
  );
}
SimpleGraph.defaultProps = DefaultProps;

export default SimpleGraph;
