import { EqaMeasurementData } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import MeasurementItem from 'components/styled-components/items/MeasurementItem';
import TableRow from 'components/styled-components/table/TableRow';
import { EQAFilterOptions } from 'helpers/eqa/usage';
import { EQAValues } from 'interfaces/common';
import lodash from 'lodash';
import styled, { useTheme } from 'styled-components';

const MeasurementRow = styled(TableRow)<{ isPdf?: boolean }>`
  display: grid;
  height: 3em;
  align-items: center;
  text-align: center;
  border: none;
  font-size: small;
  border-top: 1px solid #dcdcdc;
  ${({ isPdf }) =>
    !isPdf &&
    `
    cursor: pointer;
    :hover {
      background-color: ${useTheme().background.light};
    }
    :hover > div:first-child {
      font-weight: 700;
    }
  `}
`;

interface EQAMeasurementRowProps {
  reportData: EqaMeasurementData;
  filters?: EQAFilterOptions;
  isPdf?: boolean;
}

const DefaultProps = {
  filters: [],
  isPdf: false,
};

const isItemDisplayable = (eQAFilters: EQAFilterOptions, eQAvalue: EQAValues) =>
  !eQAFilters.values.length || eQAFilters.values.includes(eQAvalue);

const getMeasurementDisplayValue = (
  measurement: number | string,
  filter?: EQAFilterOptions,
  eqaValue?: EQAValues,
  toFixed = 3
): string | number => {
  if (!measurement) return '-';
  if (filter && eqaValue) {
    if (eqaValue === EQAValues.Note) {
      return measurement;
    }
    if (isItemDisplayable(filter, eqaValue) && typeof measurement === 'number') {
      return measurement.toFixed(toFixed);
    }
  }
  return Number(measurement).toFixed(toFixed);
};

const getMeasurementDisplayValues = (
  reportData: EqaMeasurementData,
  filters: EQAFilterOptions | undefined
): { tableValue: string; indexKey: string }[] =>
  Object.keys(EQAValues).map((key) => {
    const matchingKey = key.charAt(0).toLowerCase() + key.substring(1);
    return {
      tableValue: String(
        getMeasurementDisplayValue(
          reportData[matchingKey as keyof EqaMeasurementData],
          filters,
          EQAValues[matchingKey as keyof typeof EQAValues]
        )
      ),
      indexKey: key,
    };
  });

function EQAMeasurementRow({ reportData, filters, isPdf }: EQAMeasurementRowProps) {
  return (
    <MeasurementRow gridExpression="9% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7%" isPdf={isPdf}>
      <MeasurementItem margin={1.5}>{lodash.capitalize(reportData.level)}</MeasurementItem>
      {getMeasurementDisplayValues(reportData, filters).map((measurement) => (
        <MeasurementItem key={measurement.indexKey}>{measurement.tableValue}</MeasurementItem>
      ))}
    </MeasurementRow>
  );
}
EQAMeasurementRow.defaultProps = DefaultProps;

export default EQAMeasurementRow;
