import { EqaGraphData, EqaGraphDataPoint } from '@dewire/models/definitions/api-response/eQA/eqa-graph';
import { EqaMeasurementData } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { useTheme } from 'app/AppStyling';
import GraphInfoCard from 'components/cards/GraphInfoCard';
import SdiGraph from 'components/charts/SdiGraph';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import GraphContainer from 'components/styled-components/containers/GraphContainer';
import { getGraphDataForReport } from 'helpers/eqa/getters';
import { EQAFilterOptions } from 'helpers/eqa/usage';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import formatGraphData from 'helpers/formatters/format-graph-data';
import { LoadingState, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import EQAMeasurementRow from './EQAMeasurementRow';

const ReportMeasurementWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ReportMeasurementContent = styled.div``;
const MeasurementGraphContainer = styled.div`
  display: flex;
  background-color: ${() => useTheme().background.lightBlue};
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1em 0;
`;
const NoSdiValues = styled.p`
  text-align: center;
  padding: 0.5em 0;
`;
const LoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
`;

export interface EQAReportRowProps {
  reportData: EqaMeasurementData;
  filters: EQAFilterOptions;
  reportSerialNumber: string;
  measurementKey: string;
  year: number;
  month: number;
}

function EQAReportRow({ reportData, filters, reportSerialNumber, measurementKey, year, month }: EQAReportRowProps) {
  const [status, setStatus] = useState(LoadingState.Idle);
  const [isToggled, setIsToggled] = useState(false);
  const [graphData, setGraphData] = useState<EqaGraphDataPoint[]>();

  const getGraphInfo = () => {
    getGraphDataForReport(
      reportSerialNumber,
      measurementKey,
      reportData.level,
      year,
      month,
      (result: EqaGraphData) => {
        setStatus(LoadingState.Succeeded);
        setGraphData(result.measurements);
      },
      (errorMessage: string) => {
        setStatus(LoadingState.Failed);
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), errorMessage);
      }
    );
  };

  useEffect(() => {
    if (isToggled && !graphData) {
      setStatus(LoadingState.Loading);
      getGraphInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggled]);

  return (
    <ReportMeasurementWrapper>
      <ReportMeasurementContent onClick={() => setIsToggled(!isToggled)}>
        <EQAMeasurementRow reportData={reportData} filters={filters} />
      </ReportMeasurementContent>
      {status === LoadingState.Loading && isToggled ? (
        <LoadingSpinnerContainer>
          <LoadingSpinner containerBased inButton />
        </LoadingSpinnerContainer>
      ) : (
        status === LoadingState.Succeeded &&
        isToggled &&
        graphData &&
        (graphData.length > 0 ? (
          <MeasurementGraphContainer>
            <GraphContainer>
              <SdiGraph data={formatGraphData(graphData, false)} />
            </GraphContainer>
            <GraphInfoCard measurements={graphData} level={reportData.level} />
          </MeasurementGraphContainer>
        ) : (
          <NoSdiValues>
            Not enough QC data from peers using the same LotKit to display graph with SDI values.
          </NoSdiValues>
        ))
      )}
    </ReportMeasurementWrapper>
  );
}

export default EQAReportRow;
