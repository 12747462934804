import { IconSize } from 'interfaces/icon-size';
import styled from 'styled-components';

interface FlagIconProps {
  size?: IconSize;
}

const StyledSvg = styled.svg<{ size?: IconSize }>`
  ${({ size }) => {
    if (size === 'small') return { width: '0.5em', height: '1em' };
    if (size === 'medium') return { width: '1em', height: '1.5em' };
    if (size === 'x-large') return { width: '2em', height: '2.5em' };
    return { width: '1.5em', height: '2em' };
  }};
  path {
    fill: ${({ theme }) => theme.colors.danger.icon};
  }
`;

function FlagIcon({ size }: FlagIconProps) {
  return (
    <StyledSvg size={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6.49942V0.998851C0.799914 0.493604 1.59693 0.0223389 2.78334 0.00233898C5.95103 -0.0510646 7.06435 0.827359 9.52779 0.863804C10.9148 0.884321 12 0.571632 12 0.571632V4.00023V7.42882C12 7.42882 10.5723 7.97627 9.62479 7.99225C7.49483 8.02818 5.92108 7.49154 4.3058 7.26417C3.52853 7.16469 2.87885 7.14142 2 7.50963V9.75482V12H1H0V6.49942Z" />
    </StyledSvg>
  );
}

FlagIcon.defaultProps = {
  size: 'medium',
};

export default FlagIcon;
