import { BouleUser } from '@dewire/models/definitions/api-response/boule-user';
import Session from '@dewire/models/definitions/api-response/session';
import { SimpleUserPermissionInput } from '@dewire/models/definitions/form-input/user-permission-input';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getOriginsOverview as getOriginsOverviewFromApi,
  getUsers as getUsersFromApi,
  managePersonalUser as managePersonalUserWithApi,
} from 'api/Api';
import { LoadingState } from 'interfaces/common';
import { RequestState, RequestStateWithContent } from 'interfaces/request-state';

interface UsersState {
  users: RequestStateWithContent<BouleUser>;
  singleUser: RequestState;
  origins: {
    loadingStatus: LoadingState;
    error: string | null;
  };
  tableHeaders: RequestState;
}

const initialState: UsersState = {
  users: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  singleUser: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  origins: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
  tableHeaders: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const getUsers = createAsyncThunk('user/getUsers', async (session: Session) => getUsersFromApi(session));

export const getOriginsOverview = createAsyncThunk('user/getOriginsOverview', async (session: Session) =>
  getOriginsOverviewFromApi(session)
);

export const manageUser = createAsyncThunk(
  'user/manageUser',
  async (payload: { session: Session; user: SimpleUserPermissionInput }) =>
    managePersonalUserWithApi(payload.session, payload.user)
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => ({
        ...state,
        users: { ...state.users, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getUsers.fulfilled, (state, action) => ({
        ...state,
        users: {
          ...state.users,
          content: action.payload.data.result,
          loadingStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(getUsers.rejected, (state, action) => ({
        ...state,
        users: {
          ...state.users,
          loadingStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))

      .addCase(getOriginsOverview.pending, (state) => ({
        ...state,
        origins: {
          ...state.origins,
          loadingStatus: LoadingState.Loading,
        },
      }))
      .addCase(getOriginsOverview.fulfilled, (state) => ({
        ...state,
        origins: {
          ...state.origins,
          loadingStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(getOriginsOverview.rejected, (state, action) => ({
        ...state,
        origins: {
          ...state.origins,
          loadingStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))

      .addCase(manageUser.pending, (state) => ({
        ...state,
        singleUser: {
          ...state.singleUser,
          updateStatus: LoadingState.Loading,
        },
      }))
      .addCase(manageUser.fulfilled, (state) => ({
        ...state,
        singleUser: {
          ...state.singleUser,
          updateStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(manageUser.rejected, (state, action) => ({
        ...state,
        singleUser: {
          ...state.singleUser,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }));
  },
});
