import { QcDetails } from '@dewire/models/definitions/api-response/qc-detail';
import { useTheme } from 'app/AppStyling';
import getTranslation from 'helpers/translation/get-translation';
import styled from 'styled-components';

import QcDetailsTableRow from '../../../components/styled-components/table/QcDetailsTableRow';
import qcDisplayConfig from '../../../config/QcDisplayConfig';
import QcMeasurementDetails from './QcMesurementDetails';

const Container = styled.div`
  background: ${() => useTheme().background.light};
  width: 100%;
  padding: 1em 4em;
`;
const TableContainer = styled.div`
  background: ${() => useTheme().font.color.white};
`;

interface IQcRowDetailsProps {
  samples: QcDetails;
}

function QcRowDetails({ samples }: IQcRowDetailsProps) {
  return (
    <Container>
      <QcDetailsTableRow>
        <p>{getTranslation('Parameter')}</p>
        <p>{getTranslation('Values')}</p>
        <p />
        <p />
        <p>{getTranslation('Ind')}</p>
        <p />
        <p>{getTranslation('Scales')}</p>
        <p />
        <p>{getTranslation('Flags')}</p>
      </QcDetailsTableRow>
      <TableContainer>
        {qcDisplayConfig.map((row, index) => (
          <QcMeasurementDetails
            key={row.name}
            row={row}
            absoluteMeasurement={samples.measurements[row.valueKey]}
            relativeMeasurement={row.shareKey ? samples.measurements[row.shareKey] : undefined}
            nextRowGroup={qcDisplayConfig[index + 1]?.group}
          />
        ))}
      </TableContainer>
    </Container>
  );
}

export default QcRowDetails;
