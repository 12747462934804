import styled from 'styled-components';

const ExpandArrow = styled.button<{ disabled?: boolean }>`
  padding: 0 1em;
  color: blue;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.65' : '1')};
  filter: ${({ disabled }) => (disabled ? 'alpha(opacity=65)' : 'none')};
`;

export default ExpandArrow;
