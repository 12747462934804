import type { Distributor } from '@dewire/models/definitions/api-response/distributor';
import RefreshIcon from 'assets/icons/RefreshIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import AddDistributorModal from 'components/modals/AddDistributorModal';
import FilterModal from 'components/modals/FilterModal';
import ManageDistributorModal from 'components/modals/ManageDistributorModal';
import Paginator from 'components/paginator/Paginator';
import FilterGroup from 'components/selection/filter/FilterGroup';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import RefreshContainer from 'components/styled-components/containers/RefreshContainer';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import SearchField from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllDistributors } from 'helpers/organization/getters';
import { manageExistingDistributor } from 'helpers/organization/manage';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';
import DistributorRow from './DistributorRow';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;
const SearchBarContainer = styled.div`
  width: 40em;
  ${() => bpTheme.breakpoints.down('lg')} {
    width: 30em;
  }
`;

interface DistributorAdminProps {
  navigateCallback: (_distributor: Distributor, viewType: 'customers' | 'sites' | 'instruments') => void;
}

function DistributorAdmin({ navigateCallback }: DistributorAdminProps) {
  const [headers, distributorsState, marketsState] = useAppSelector((state) => [
    state.headers,
    state.admin.distributors.content,
    state.admin.markets.content,
  ]);

  const filterOptions = marketsState.map((market) => market.name);
  const [search, setSearch] = useState('');
  const [currentDistributor, setCurrentDistributor] = useState<Distributor>();
  const [filteredDistributors, setFilteredDistributors] = useState<Distributor[]>([]);
  const [sortedDistributors, setSortedDistributors] = useState<Distributor[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [loading, setLoading] = useState(distributorsState.length === 0);
  const [addDistributorToggled, setAddDistributorToggled] = useState(false);
  const [page, setPage] = useState(1);
  const pageLimit = 8;
  const tableHeaders = ['Distributor', 'Market', 'Country', 'Customers', 'Instruments'];
  const isFilterActive = filter.length !== 0;

  const handleFilter = (option: string) => {
    const tempFilters = [...filter];
    if (filter.includes(option)) {
      setFilter(tempFilters.filter((element) => element !== option));
    } else {
      tempFilters.push(option);
      setFilter(tempFilters);
    }
  };

  const handleFilterClear = () => {
    setFilter([]);
  };

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const refreshDistributors = () => {
    setLoading(true);
  };

  const handleAddDistributorClose = () => {
    setAddDistributorToggled(false);
  };

  const saveManagedDistributor = (managedDistributor: Distributor) => {
    manageExistingDistributor(managedDistributor.formData, () => {
      provideSnackbar(
        Status.Success,
        lodash.capitalize(Status.Success),
        `Distributor "${managedDistributor.name}" was successfully changed.`
      );
      setCurrentDistributor(undefined);
    });
  };

  useEffect(() => {
    if (loading) {
      getAllDistributors(() => setLoading(false));
    } else if (distributorsState.length === 0) {
      provideSnackbar(Status.Info, 'Status', 'No distributors currently exist.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (filter.length > 0) {
      setFilteredDistributors([...distributorsState.filter((distributor) => filter.includes(distributor.marketName))]);
    } else {
      setFilteredDistributors(distributorsState);
    }
  }, [distributorsState, filter]);

  useEffect(() => {
    setSortedDistributors(lodash.sortBy(filteredDistributors, (item) => item.name.toLowerCase()));
  }, [filteredDistributors]);

  const availableDistributors = sortedDistributors
    .filter(
      (distributor) =>
        !search.length ||
        (search.length && distributor.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && distributor.country.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    )
    .map((_distributor) => (
      <DistributorRow
        key={_distributor.formData.id}
        distributor={_distributor}
        customersCallback={() => navigateCallback(_distributor, 'customers')}
        instrumentsCallback={() => navigateCallback(_distributor, 'instruments')}
        manageDistributorCallback={() => setCurrentDistributor(_distributor)}
      />
    ));

  return (
    <div>
      {currentDistributor && (
        <ManageDistributorModal
          distributor={currentDistributor}
          onSaveCallback={(distributor: Distributor) => saveManagedDistributor(distributor)}
          onCloseCallback={() => setCurrentDistributor(undefined)}
        />
      )}
      {addDistributorToggled && <AddDistributorModal onCloseCallback={() => handleAddDistributorClose()} />}
      <ToolbarContainer>
        <ToolbarContainer>
          <SearchBarContainer>
            <SearchField
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              iconUrl={magnyfyingGlass}
            />
          </SearchBarContainer>
          <FilterModal isFiltering={isFilterActive} clearCallback={() => handleFilterClear()}>
            <FilterGroup
              filterOptions={filterOptions}
              filterArray={filter}
              groupWidth={12}
              onFilterCallback={(filterValue) => handleFilter(filterValue)}
            />
          </FilterModal>
        </ToolbarContainer>
        <ToolbarContainer>
          {headers.accesses.organization.distributors.edit && (
            <PrimaryButton rounded type="button" onClick={() => setAddDistributorToggled(true)}>
              {getTranslation('Add distributor')}
            </PrimaryButton>
          )}
          <RefreshContainer onClick={() => refreshDistributors()}>
            <RefreshIcon />
          </RefreshContainer>
        </ToolbarContainer>
      </ToolbarContainer>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="20% 20% 20% 20% 18% 5%" textAlign="start">
            {tableHeaders.map((tableHeader: string) => (
              <p key={tableHeader}>{getTranslation(tableHeader)}</p>
            ))}
          </TableHeader>
          {loading ? (
            <LoadingSpinner containerBased />
          ) : (
            <div>{availableDistributors.slice(page * pageLimit - pageLimit, page * pageLimit)}</div>
          )}
        </Table>
      </HistoryTable>
      {availableDistributors.length > pageLimit && !loading && (
        <Paginator
          currentPage={page}
          maxPages={Math.ceil(availableDistributors.length / pageLimit)}
          paginationCallback={(_page: number) => handlePagination(_page)}
        />
      )}
    </div>
  );
}

export default DistributorAdmin;
