import type { InstrumentOverviewCard, Notice } from '@dewire/models/definitions/api-response/qc-overview-card';
import { StatusType } from '@dewire/models/definitions/status-type';
import { useTheme } from 'app/AppStyling';
import ErrorSourceIcon from 'assets/icons/QcIcon';
import StatusIcon from 'assets/icons/StatusIcon';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import getTextColor from 'helpers/styling/text-color';
import { TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 15em;
  height: 23em;
  min-height: 20em;
  background-color: ${() => useTheme().font.color.white};
  box-shadow: 2px 5px 5px ${() => useTheme().shadow.secondary};
  color: ${() => useTheme().font.color.black};
`;
const CardRow = styled.div`
  height: 100%;
`;
const CardHeader = styled(CardRow)`
  padding: 1em;
  justify-content: space-between;
  gap: 0.5em;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tertiary};
  min-height: 7.5em;
  max-height: 7.5em;
`;
const CardContent = styled(CardRow)`
  padding: 1em;
  gap: 1em;
`;
const TextContainer = styled.div`
  margin: 0;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
const StatusWrapper = styled(FlexContainer)`
  padding-top: 0.5em;
  margin: auto;
  gap: 1em;
`;

export interface IInstrument {
  id: string;
  distributor_id: string;
  sn: string;
  instrumentid: string;
  name: string;
  model: string;
  sw_version: string;
  updated_at: string;
  created_at: string;
  gatewayId: string;
}

export interface ISite {
  id: string;
  site_id: string;
  distributor_id: string;
  name: string;
  status: string;
  loc_longitude: string;
  loc_latitude: string;
  updated_at: string;
  created_at: string;
}

interface QcCardActionProps {
  qc: InstrumentOverviewCard;
  onMouseLeaveCallback?: (index: number) => void;
  isToggled?: boolean;
}

function QcCardAction({
  qc: { sampleCreatedAt, serialNumber, siteName, city, notices, status = 'unknown' },
  onMouseLeaveCallback,
  isToggled,
}: QcCardActionProps) {
  const [iconType, setIconType] = useState<StatusType>('unknown');
  const createdAt = dayjs(sampleCreatedAt);

  function deciferNotice(notice: Notice): JSX.Element {
    const textColor = getTextColor(notice.status);
    return (
      <StatusWrapper key={notice.message}>
        <ErrorSourceIcon status={notice.status} type={notice.errorSource} size="large" />
        <TextWrapper color={textColor}>{notice.message}</TextWrapper>
      </StatusWrapper>
    );
  }

  useEffect(() => {
    setIconType(status);
  }, [status]);

  return (
    <Container
      className="instrumentCard"
      onMouseLeave={() => {
        if (isToggled === false) onMouseLeaveCallback?.(-1);
      }}
    >
      <CardHeader>
        <TextContainer>
          {siteName && (
            <TextWrapper bold rows={2}>
              {siteName}
            </TextWrapper>
          )}
        </TextContainer>
        <TextContainer>{city && <TextWrapper bold>{city}</TextWrapper>}</TextContainer>
        <TextWrapper className="serialNumber">{serialNumber}</TextWrapper>
      </CardHeader>
      <CardContent>
        <TextWrapper bold>Status</TextWrapper>
        <StatusWrapper>
          <StatusIcon status={iconType} size="large" />
          <TextContainer>
            {sampleCreatedAt && <TextWrapper color={TextColor.Tertiary}>{createdAt.format('YYYY-MM-DD')}</TextWrapper>}
            {sampleCreatedAt && <TextWrapper color={TextColor.Tertiary}>{createdAt.format('HH:mm:ss')}</TextWrapper>}
          </TextContainer>
        </StatusWrapper>
      </CardContent>
      <CardContent>
        {notices.length > 0 && <TextWrapper bold>Notices</TextWrapper>}
        {notices.map((n) => deciferNotice(n))}
      </CardContent>
    </Container>
  );
}

QcCardAction.defaultProps = {
  onMouseLeaveCallback: undefined,
  isToggled: undefined,
};

export default QcCardAction;
