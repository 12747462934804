import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import { useTheme } from 'app/AppStyling';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import PenIcon from 'assets/icons/PenIcon';
import InTableButton from 'components/styled-components/buttons/InTableButton';
import TableRow from 'components/styled-components/table/TableRow';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const RowItem = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12em;
  ${() => bpTheme.breakpoints.down('lg')} {
    max-width: 8em;
  }
`;
const IconContainer = styled.div`
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid ${() => useTheme().colors.blue};
  }
`;

interface InstrumentRowProps {
  instrument: Instrument;
  customersCallback: (parent: Distributor) => void;
  sitesCallback: (parent: Customer) => void;
  manageInstrumentCallback: () => void;
}

function InstrumentRow({ instrument, customersCallback, sitesCallback, manageInstrumentCallback }: InstrumentRowProps) {
  const [headersState, distributorsState, customersState] = useAppSelector((state) => [
    state.headers,
    state.admin.distributors.content,
    state.admin.customers.content,
  ]);

  const instrumentAccess = headersState.accesses.organization.instruments;

  const findDistributorParent = () => {
    const distributor = distributorsState.find(
      (distributorValue) => distributorValue.formData.id === instrument.formData.distributorId
    );
    return distributor;
  };

  const findCustomerParent = () => {
    const customer = customersState.find(
      (customerValue) => customerValue.formData.id === instrument.formData.customerId
    );
    return customer;
  };

  const renderCustomerName = () => {
    if (instrument.customerName) {
      return headersState.accesses.organization.customers.view ? (
        <InTableButton onClick={() => sitesCallback(findCustomerParent() as Customer)}>
          <RowItem>{instrument.customerName}</RowItem>
          <ExpandArrowIcon open={false} color="black" />
        </InTableButton>
      ) : (
        <RowItem>{instrument.customerName}</RowItem>
      );
    }
    return <div />;
  };

  return (
    <TableRow className="instrumentTable" gridExpression="17% 20% 23% 20% 18% 5%">
      <RowItem className="serialNumber">{instrument.serialNumber}</RowItem>
      {headersState.accesses.organization.distributors.view ? (
        <InTableButton onClick={() => customersCallback(findDistributorParent() as Distributor)}>
          {instrument.distributorName}
          <ExpandArrowIcon open={false} color="black" />
        </InTableButton>
      ) : (
        <RowItem>{instrument.distributorName}</RowItem>
      )}
      {renderCustomerName()}
      <RowItem>
        {instrument.country} {instrument.city ? `- ${instrument.city}` : ''}
      </RowItem>
      <RowItem>{instrument.model}</RowItem>
      {instrumentAccess.edit && (
        <RowItem onClick={() => manageInstrumentCallback()}>
          <IconContainer>
            <PenIcon />
          </IconContainer>
        </RowItem>
      )}
    </TableRow>
  );
}

export default InstrumentRow;
