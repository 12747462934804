import { StatusType } from '@dewire/models/definitions/status-type';
import { Status } from 'interfaces/common';
import { DefaultTheme } from 'styled-components';

import { IconColor } from '../../styled';

interface CustomPinProps {
  number: number;
  active: boolean;
  status: StatusType;
  theme: DefaultTheme;
}

function getColorPalet(status: StatusType, theme: DefaultTheme): IconColor {
  switch (status) {
    case Status.Success:
      return theme.colors.success;
    case Status.Warning:
      return theme.colors.warning;
    case Status.Failure:
      return theme.colors.danger;
    case Status.Unknown:
      return theme.colors.unknown;
    default:
      return theme.colors.unknown;
  }
}

function CustomPin({ status, theme, number, active }: CustomPinProps) {
  const colorPalet = getColorPalet(status, theme);
  const fillColor = active ? colorPalet.pinBorder : colorPalet.pin;

  return (
    <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-30.000000, -19.000000)">
          <g id="marker" transform="translate(30.620805, 19.682927)">
            <rect id="Rectangle" x="0" y="1.31707317" width="34" height="35" />
            <path
              d="M17.4110738,9 C22.8084732,9 27.6879195,13.2362509 27.6879195,19.8225064 C27.6879195,21.9761297 26.8429464,24.3828331 25.0751126,27.0572687 C23.36656,29.6416832 20.8172532,32.4435469 17.4110738,35.4634146 C14.0049161,32.4435469 11.4555985,29.6416832 9.74708915,27.0572687 C7.97915798,24.3828331 7.13422819,21.9761297 7.13422819,19.8225064 C7.13422819,13.2362509 12.0136637,9 17.4110738,9 Z M22.00861,19.8225064 C22.00861,17.2171018 19.9502119,15.1050036 17.4110738,15.1050036 C14.8719249,15.1050036 12.8135376,17.2171018 12.8135376,19.8225064 C12.8135376,22.4278999 14.8719249,24.5400092 17.4110738,24.5400092 C19.9502119,24.5400092 22.00861,22.4278999 22.00861,19.8225064 Z"
              id="Shape"
              stroke={colorPalet.pinBorder}
              fill={fillColor}
              fillRule="nonzero"
            />
            {number > 1 && (
              <>
                <ellipse
                  id="Oval"
                  fill={colorPalet.iconBackground}
                  cx="26.8657718"
                  cy="9"
                  rx="7.39932886"
                  ry="7.68292683"
                />
                <text
                  id="4"
                  fontFamily="Nunito Sans, sans-serif"
                  fontSize={number > 9 ? 10 : 12}
                  fontWeight="normal"
                  fill="#060708"
                >
                  <tspan x="26.5" y="13" textAnchor="middle">
                    {number}
                  </tspan>
                </text>
              </>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CustomPin;
