import InstrumentCycleCounter from '@dewire/models/definitions/api-response/instrument-cycle-counter';
import { InstrumentQcOverview } from '@dewire/models/definitions/api-response/instrument-overview';
import { getInstrumentQcOverview, getInstrumentTotalCycles } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import QcCardDisplay from 'components/cards/QcCardDisplay';
import InstrumentHeader from 'components/instrument-header/InstrumentHeader';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import Header3 from 'components/styled-components/headers/Header3';
import HeaderWithDateContainer from 'components/styled-components/headers/HeaderWithDateContainer';
import DateStampWrapper from 'components/styled-components/wrappers/DateStampWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import LatestBackground from './background/LatestBackground';
import TotalCycles from './cycles/TotalCycles';

const FlexViewContainer = styled.div`
  display: flex;
  gap: 2em;
  padding: 2em;
`;
const TopSection = styled.div`
  border-bottom: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  padding-right: 2em;
  min-width: 783px;
`;
const QcCardContainer = styled.div<{ isLoading: boolean }>`
  display: flex;
  gap: 1em;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};
  align-items: center;
  min-height: 18em;
  padding-bottom: 1em;
`;
const EmptyContainer = styled.div`
  margin-top: 0.37em;
`;
const BottomSection = styled.div`
  border-bottom: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  padding-right: 2em;
  padding-bottom: 2em;
`;
const BackgroundContainer = styled.div`
  min-width: 305px;
`;
const LeftColumn = styled.div`
  border-right: ${({ theme }) => theme.breakLine.soft};
`;
const LinkText = styled(Link)`
  text-decoration: underline;
  color: ${() => useTheme().font.color.black};
  :hover {
    font-weight: 600;
  }
`;

function InstrumentView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const headersState = useAppSelector((state) => state.headers);

  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState('');
  const [totalCyclesApiError, setTotalCyclesApiError] = useState('');
  const [instrumentLogCycles, setInstrumentLogCycles] = useState<InstrumentCycleCounter>();
  const [instrumentQcOverview, setInstrumentQcOverview] = useState<InstrumentQcOverview>();

  const fetchCycles = () => {
    if (id) {
      getInstrumentTotalCycles(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentLogCycles(res.data.result);
        } else {
          setTotalCyclesApiError(res.status);
        }
      });
    } else navigate('/');
  };

  const fetchOverview = () => {
    if (id)
      getInstrumentQcOverview(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentQcOverview(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
  };

  useEffect(() => {
    fetchCycles();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchOverview();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (instrumentQcOverview || apiError) setLoading(false);
    return () => {};
  }, [instrumentQcOverview, apiError]);

  return (
    <div>
      {id && <InstrumentHeader instrumentId={id} />}
      <FlexViewContainer>
        <LeftColumn>
          <TopSection>
            <HeaderWithDateContainer>
              <Header3>
                <LinkText to="qc">Quality Control</LinkText>
              </Header3>
            </HeaderWithDateContainer>
            <EmptyContainer />
            <QcCardContainer isLoading={loading}>
              {loading ? (
                <LoadingSpinner containerBased />
              ) : (
                <>
                  {instrumentQcOverview?.qc.map((qc) => (
                    <QcCardDisplay
                      key={qc.displayName}
                      qc={{
                        ...qc,
                        sampleCreatedAt: qc.time,
                        reportCreatedAt: qc.expiryTime,
                        controlBloodExpiry: qc.expiryTime,
                      }}
                    />
                  ))}
                  {apiError !== '' && <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>}
                </>
              )}
            </QcCardContainer>
          </TopSection>
          <BottomSection>
            <TitleWrapper>
              <HeaderWithDateContainer>
                {totalCyclesApiError === '' ? (
                  <>
                    <div>
                      <Header3>
                        <LinkText to="instrument-log">Instrument log</LinkText>
                      </Header3>
                      <TextWrapper subtitle>
                        Instrument configured :{' '}
                        {instrumentLogCycles?.instrumentConfigured
                          ? dayjs(instrumentLogCycles.instrumentConfigured).format('YYYY-MM-DD')
                          : ' ---'}
                      </TextWrapper>
                    </div>
                    <DateStampWrapper>
                      <TextWrapper color={TextColor.Tertiary}>{getTranslation('Last export')}</TextWrapper>
                      <TextWrapper color={TextColor.Tertiary}>
                        {instrumentLogCycles?.lastExport
                          ? dayjs(instrumentLogCycles.lastExport).format('YYYY-MM-DD HH:mm')
                          : '----'}
                      </TextWrapper>
                    </DateStampWrapper>
                  </>
                ) : (
                  <TextWrapper color={TextColor.Danger}>{totalCyclesApiError}</TextWrapper>
                )}
              </HeaderWithDateContainer>
            </TitleWrapper>
            <TotalCycles />
          </BottomSection>
        </LeftColumn>
        <BackgroundContainer>
          <LatestBackground />
        </BackgroundContainer>
      </FlexViewContainer>
    </div>
  );
}

export default InstrumentView;
