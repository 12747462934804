import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const SearchField = styled.input<{ iconUrl: string; dark?: boolean }>`
  width: 100%;
  min-width: 25em;
  padding: 1em;
  margin: 2em 1em 2em 0;
  box-shadow: inset 1px 1px 1px ${() => useTheme().shadow.secondary};
  border: none;
  background-image: url(${({ iconUrl }) => iconUrl});
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  background-color: ${({ dark }) => (dark ? useTheme().background.light : useTheme().font.color.white)};
`;

export default SearchField;
