import { combineReducers, configureStore } from '@reduxjs/toolkit';

import activitySliceReducer from './slices/activity.slice';
import { adminSlice } from './slices/admin.slice';
import { eqaSlice } from './slices/eqa.slice';
import { filesSlice } from './slices/files.slice';
import { headersSlice } from './slices/headers.slice';
import { snackbarSlice } from './slices/snackbar.slice';
import { usersSlice } from './slices/users.slice';

export const rootReducer = combineReducers({
  headers: headersSlice.reducer,
  admin: adminSlice.reducer,
  eqa: eqaSlice.reducer,
  users: usersSlice.reducer,
  files: filesSlice.reducer,
  activity: activitySliceReducer,
  snackbar: snackbarSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
