import { UploadHistoryEntry } from '@dewire/models/definitions/api-response/upload-history-entry';
import { useTheme } from 'app/AppStyling';
import InfoIcon from 'assets/icons/InfoIcon';
import InfoIconModal from 'components/modals/InfoIconModal';
import TableRow from 'components/styled-components/table/TableRow';
import dayjs from 'dayjs';
import styled from 'styled-components';

import errorIcon from '../../assets/icons/fileError.svg';

const FileRowContainer = styled.div`
  margin: 0.2em 0;
`;
const FileTableRow = styled(TableRow)<{ rowColor: string }>`
  background: ${({ rowColor }) => rowColor};
`;
const ErrorRow = styled.div`
  display: flex;
  grid-template-columns: 23% 77%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-bottom: ${({ theme }) => theme.breakLine.soft};
  min-height: 3em;
  padding: 1em;
  font-size: smaller;
`;
const ErrorRowItem = styled.div<{ margin?: number }>`
  display: flex;
  margin: 0 ${({ margin }) => margin}em;
  color: ${() => useTheme().font.color.error};
  font-weight: ${() => useTheme().font.weight.bold};
`;
const RowItem = styled.div`
  display: flex;
  align-items: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const InfoItem = styled.div`
  font-size: small;
  text-align: left;
  gap: 0.5em;
  display: flex;
`;
const UploadInfoTitle = styled.h4<{ uploaded: boolean }>`
  color: ${({ uploaded }) => (uploaded ? useTheme().font.color.uploadSuccess : useTheme().font.color.uploadFailed)};
  font-weight: ${() => useTheme().font.weight.bold};
`;
const InfoRowItem = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`;
interface IFileRowProps {
  file: UploadHistoryEntry;
  color: boolean;
}

function FileRow({ file, color }: IFileRowProps) {
  const theme = useTheme();
  const exportDate = dayjs(file.exportDate);
  const uploadDate = dayjs(file.uploadDate);
  const fileType: string = file.exportedFile;

  function getRowColor() {
    if (color) return theme.background.blue;
    return theme.colors.lightblue;
  }

  function getIconColorByErrorMessage() {
    if (file.error.errorTypeId === 501) return theme.icon.QCUploadSuccessNoAssay;
    return theme.icon.uploadSuccess;
  }

  return (
    <FileRowContainer>
      {file.error.errorMessage !== '' && file.error.errorTypeId !== 501 ? (
        <ErrorRow>
          <ErrorRowItem margin={3}>
            <img src={errorIcon} alt="error-icon" />
          </ErrorRowItem>
          <ErrorRowItem>
            Error on uploading file: {file.fileName}. {file.error.errorMessage}
          </ErrorRowItem>
          <ErrorRowItem />
        </ErrorRow>
      ) : (
        <FileTableRow className="tableRow" rowColor={getRowColor()} gridExpression="25% 20% 25% 20% 10%">
          <RowItem className="exportedFileName">{file.exportedFile}</RowItem>
          <RowItem>{file.user}</RowItem>
          <RowItem>{uploadDate.format('YYYY-MM-DD HH:mm:ss')}</RowItem>
          <RowItem title={file.fileName}>{file.fileName}</RowItem>
          <InfoRowItem>
            {file.exportedFile === 'Sample Export' && (
              <InfoIconModal text="" icon={<InfoIcon color={getIconColorByErrorMessage()} />} leftAlign={-20}>
                <InfoItem>
                  <h4>Instrument: </h4>
                  {file.instrument ? file.instrument : 'No instrument'}
                </InfoItem>
                <InfoItem>
                  <h4>Site: </h4>
                  {file.site ? file.site : 'No site'}
                </InfoItem>
                <InfoItem>
                  <h4>Exported date: </h4>
                  {file.exportDate ? exportDate.format('YYYY-MM-DD HH:mm:ss') : ' -- -- '}
                </InfoItem>
                <InfoItem>
                  <UploadInfoTitle uploaded={file.error.errorTypeId !== 501}>EQA: </UploadInfoTitle>
                  {file.error.errorTypeId === 501
                    ? `${file.error.errorMessage}`
                    : 'Upload successful for EQA reporting'}
                </InfoItem>
                <InfoItem>
                  <UploadInfoTitle uploaded={fileType === 'Sample Export'}>QC: </UploadInfoTitle>
                  {fileType === 'Sample Export'
                    ? 'Upload successful for instrument dashboard'
                    : `${file.error.errorMessage}`}
                </InfoItem>
              </InfoIconModal>
            )}
          </InfoRowItem>
        </FileTableRow>
      )}
    </FileRowContainer>
  );
}

export default FileRow;
