import { useTheme } from 'app/AppStyling';

const DefaultProps = {
  rotation: 0,
};

function PageNextArrowIcon({ rotation }: { rotation?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      style={{ transform: `rotate(${rotation}deg)`, transition: 'transform .5s' }}
    >
      <path
        d="M13.9951 10.6174C13.5878 11.0103 13.5875 11.6626 13.9943 12.0559L18.2185 16.1406C18.4218 16.3371 18.4218 16.6629 18.2185 16.8594L13.9943 20.9441C13.5875 21.3374 13.5878 21.9897 13.9951 22.3826L14.1997 22.5801C14.5871 22.9539 15.201 22.9539 15.5884 22.5801L21.1441 17.2196C21.5517 16.8264 21.5517 16.1736 21.1441 15.7804L15.5884 10.4199C15.201 10.0461 14.5871 10.0461 14.1997 10.4199L13.9951 10.6174Z"
        fill={useTheme().border.gray}
      />
    </svg>
  );
}

PageNextArrowIcon.defaultProps = DefaultProps;

export default PageNextArrowIcon;
