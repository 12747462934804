import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import { Site } from '@dewire/models/definitions/api-response/site';

type Validate = 'required' | 'requiredId' | 'number' | 'email' | 'phone' | 'character';

export default function validateInput(input: {
  value: string | undefined;
  params: Validate[];
  limit?: number;
  distributors?: Distributor[];
  customers?: Customer[];
  sites?: Site[];
  instruments?: Instrument[];
}): { error: boolean; errorMessage?: string } {
  if (input.params.includes('required') && (input.value === '' || !input.value)) {
    return {
      error: true,
      errorMessage: `Please enter a valid ${input.params.includes('number') ? 'serial number' : 'name'}`,
    };
  }
  if (input.params.includes('requiredId') && input.value === undefined) {
    return {
      error: true,
      errorMessage: 'Please enter a valid id',
    };
  }
  if (input.params.includes('number') && input.value && !input.value.match(/^\d+$/)) {
    return {
      error: true,
      errorMessage: 'Please only enter digits',
    };
  }
  if (
    input.distributors &&
    input.distributors.find(
      (distributor: Distributor) => distributor.name.toLocaleLowerCase() === input.value!.toLocaleLowerCase()
    ) !== undefined
  ) {
    return {
      error: true,
      errorMessage: 'A distributor with this name already exists',
    };
  }
  if (
    input.customers &&
    input.customers.find(
      (customer: Customer) => customer.name.toLocaleLowerCase() === input.value!.toLocaleLowerCase()
    ) !== undefined
  ) {
    return {
      error: true,
      errorMessage: 'A customer with this name already exists',
    };
  }
  if (
    input.sites &&
    input.sites.find((site: Site) => site.name.toLocaleLowerCase() === input.value!.toLocaleLowerCase()) !== undefined
  ) {
    return {
      error: true,
      errorMessage: 'A site with this name already exists',
    };
  }
  if (
    input.instruments &&
    input.instruments.find(
      (instrument: Instrument) => instrument.serialNumber.toLocaleLowerCase() === input.value!.toLocaleLowerCase()
    ) !== undefined
  ) {
    return {
      error: true,
      errorMessage: 'An instrument with this serial number already exists',
    };
  }
  if (
    input.params.includes('email') &&
    input.value &&
    !input.value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return {
      error: true,
      errorMessage: 'Please enter a valid email address',
    };
  }
  if (input.params.includes('phone') && input.value && !input.value.match(/^[0-9\\+\\(\\)\\+\\[\]\-\\/]+$/)) {
    return {
      error: true,
      errorMessage: 'Please enter a valid phone number',
    };
  }
  if (input.limit && input.value && input.value.length > input.limit) {
    return {
      error: true,
      errorMessage: `Maximum ${input.limit} characters, now ${input.value}`,
    };
  }
  return {
    error: false,
  };
}
