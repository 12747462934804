import { TextColor } from 'interfaces/common';

import getTranslation from '../../helpers/translation/get-translation';
import Flex from '../flex/Flex';
import { InfoTableItem } from '../styled-components/table/info-table-style';
import TextWrapper from '../styled-components/wrappers/TextWrapper';

interface SummaryItemCardProps {
  titleTranslationKey: string;
  value: number;
}

// TODO: use in other places like totalCycles
function SummaryItemCard({ titleTranslationKey, value }: SummaryItemCardProps) {
  return (
    <InfoTableItem>
      <TextWrapper size="large" bold>
        {getTranslation(titleTranslationKey)}
      </TextWrapper>
      <Flex gap="5px" alignItems="flex-end">
        <TextWrapper size="large">{value}</TextWrapper>
        <TextWrapper color={TextColor.Tertiary}>samples</TextWrapper>
      </Flex>
    </InfoTableItem>
  );
}

export default SummaryItemCard;
