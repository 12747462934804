import Session from '@dewire/models/definitions/api-response/session';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFileUploadUrl as getFileUploadUrlFromApi, getUploadedFiles as getUploadedFilesFromApi } from 'api/Api';
import { LoadingState } from 'interfaces/common';
import { RequestState } from 'interfaces/request-state';

interface FilesState {
  upload: RequestState;
  history: RequestState;
}

const initialState: FilesState = {
  upload: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  history: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const getUploadUrl = createAsyncThunk(
  'files/getUploadUrl',
  async (payload: { fileName: string; isAssay: boolean; session: Session }) =>
    getFileUploadUrlFromApi(payload.fileName, payload.isAssay, payload.session)
);

export const getFileHistory = createAsyncThunk('files/getFileHistory', async (session: Session) =>
  getUploadedFilesFromApi(session)
);

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFileHistory.pending, (state) => ({
        ...state,
        history: { ...state.history, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getFileHistory.fulfilled, (state) => ({
        ...state,
        history: {
          ...state.history,
          loadingStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(getFileHistory.rejected, (state, action) => ({
        ...state,
        history: {
          ...state.history,
          loadingStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))

      .addCase(getUploadUrl.pending, (state) => ({
        ...state,
        upload: { ...state.upload, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getUploadUrl.fulfilled, (state) => ({
        ...state,
        upload: {
          ...state.upload,
          loadingStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(getUploadUrl.rejected, (state, action) => ({
        ...state,
        upload: {
          ...state.upload,
          loadingStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }));
  },
});

export default filesSlice.reducer;
