import Session from '@dewire/models/definitions/api-response/session';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import clearUserSession from 'helpers/resets/clear-user-session';
import { Status } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';
import lodash from 'lodash';

export const getAuthHeaders = (session: Session): Headers => {
  const headers = new Headers();
  headers.append('Authorization', `${session.idToken}`);
  headers.append('access_token', `${session.accessToken}`);
  return headers;
};

export async function getRequest<T>(
  session: Session,
  url: string,
  errorReturn: T,
  isPdf = false
): Promise<FullGetResponse<T>> {
  try {
    const response = await fetch(`${url}`, {
      headers: getAuthHeaders(session),
    });
    if (response.status === 401) {
      clearUserSession();
      return { status: 'Not authorized', data: { result: errorReturn } };
    }
    return isPdf ? await response.text() : await response.json();
  } catch (e) {
    provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Something went wrong when calling API');
    return { status: 'Unexpected error occured when calling API', data: { result: errorReturn } };
  }
}
