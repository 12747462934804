import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const PrimaryButton = styled.button<{ disabled?: boolean; rounded?: boolean }>`
  display: inline-block;
  background-color: ${() => useTheme().background.blue};
  color: ${() => useTheme().colors.blue};
  width: 11em;
  height: 3em;
  font-weight: bold;
  line-height: 3em;
  margin: 1em;
  border-radius: ${({ rounded }) => (rounded ? '2em' : '0.5em')};
  cursor: pointer;
  border: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.65' : '1')};
  filter: ${({ disabled }) => (disabled ? 'alpha(opacity=65)' : 'none')};
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${() => useTheme().colors.blue};
  }
`;

export default PrimaryButton;
