import { DefaultTheme, useTheme } from 'app/AppStyling';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import UserManual from 'components/user-manual/UserManual';
import locateEnvironment from 'helpers/environment/locate-environment';
import getTranslation from 'helpers/translation/get-translation';
import { Environments, Paths, Views } from 'interfaces/common';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
`;
const MenuLinkItem = styled(Link)<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  text-decoration: none;
  color: ${({ color }) => color};
  margin: 0em 3em;
  :hover {
    color: ${() => useTheme().icon.blue};
  }
`;
const MenuItem = styled.div<{ color: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  text-decoration: none;
  color: ${({ color }) => color};
  margin: 0em 3em;
  :hover {
    color: ${() => useTheme().icon.blue};
  }
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.25em 0.6em;
`;
const MenuItemText = styled.div`
  cursor: pointer;
  ::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  ::-moz-selection {
    color: none;
    background: none;
  }
`;
const ProfileMenuPopup = styled.div`
  cursor: auto;
  position: absolute;
  z-index: 999;
  min-height: 2em;
  padding: 1em;
  width: 10em;
  background: ${() => useTheme().font.color.white};
  box-shadow: 1px 3px 10px ${() => useTheme().shadow.secondary};
  border-radius: 3px;
  top: 55px;
  right: 74px;
  -ms-grid-rows: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const MenuPopupText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3em 0;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const ManualWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${() => useTheme().icon.blue};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0.3em 0;
`;

function HeaderMenu({ clearSessionCallback }: { clearSessionCallback: () => void }) {
  const theme = useTheme();
  const location = useLocation();
  const dropdownRef = useRef<any>(null);
  const menuButtonRef = useRef<any>(null);
  const headersState = useAppSelector((state) => state.headers);

  const [profileToggled, setProfileToggled] = useState(false);
  const [userMenuHovered, setUserMenuHovered] = useState(false);
  const [toggledItem, setToggledItem] = useState('instruments');

  const navigateToProfileView = () => {
    window.location.href = '/profile';
  };

  const clickOutsideDropdownHandler = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      menuButtonRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !menuButtonRef.current.contains(e.target)
    ) {
      setProfileToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideDropdownHandler);
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropdownHandler);
    };
  }, [dropdownRef, profileToggled]);

  useEffect(() => {
    setToggledItem(location.pathname);
  }, [location.pathname]);

  return (
    <Container id="header-menu-ref">
      {headersState.accesses.instrumentMonitoring && (
        <MenuLinkItem
          className="instruments"
          to={Paths.InstrumentsUrl}
          color={toggledItem === Paths.InstrumentsUrl ? theme.colors.primary : theme.font.color.white}
        >
          <MenuItemText>{getTranslation(Views.Instruments)}</MenuItemText>
        </MenuLinkItem>
      )}
      {locateEnvironment([
        Environments.Dev,
        Environments.Cont,
        Environments.Test,
        Environments.Demo,
        Environments.Staging,
      ]) &&
        headersState.accesses.instrumentMonitoring && (
          <MenuLinkItem
            to={Paths.EQAUrl}
            className="eqaNavigation"
            color={toggledItem === Paths.EQAUrl ? theme.colors.primary : theme.font.color.white}
          >
            <MenuItemText>{getTranslation(Views.EQA)}</MenuItemText>
          </MenuLinkItem>
        )}
      {headersState.accesses.instrumentFileUpload && (
        <MenuLinkItem
          to={Paths.FileUploadUrl}
          className="fileUpload"
          color={toggledItem === Paths.FileUploadUrl ? theme.colors.primary : theme.font.color.white}
        >
          <MenuItemText>{getTranslation(Views.FileUpload)}</MenuItemText>
        </MenuLinkItem>
      )}
      {headersState.accesses.organization.instruments.view && (
        <MenuLinkItem
          to={Paths.OrganizationUrl}
          className="organizationNavigation"
          color={toggledItem === Paths.OrganizationUrl ? theme.colors.primary : theme.font.color.white}
        >
          <MenuItemText>{getTranslation(Views.Organization)}</MenuItemText>
        </MenuLinkItem>
      )}
      {headersState.accesses.users && (
        <MenuLinkItem
          to={Paths.UsersUrl}
          color={toggledItem === Paths.UsersUrl ? theme.colors.primary : theme.font.color.white}
        >
          <MenuItemText>{getTranslation(Views.Users)}</MenuItemText>
        </MenuLinkItem>
      )}
      <MenuItem
        className="userProfile"
        onClick={() => setProfileToggled(!profileToggled)}
        color={profileToggled ? theme.icon.blue : theme.font.color.white}
        onMouseEnter={() => setUserMenuHovered(true)}
        onMouseLeave={() => setUserMenuHovered(false)}
        ref={menuButtonRef}
      >
        <MenuItemText>{headersState.userName}</MenuItemText>
        <ExpandArrowIcon color={userMenuHovered || profileToggled ? theme.icon.blue : theme.font.color.white} />
        {profileToggled && (
          <ProfileMenuPopup ref={dropdownRef}>
            <MenuPopupText className="userProfilePopup" onClick={() => navigateToProfileView()}>
              {`${getTranslation('My Profile')}`}
            </MenuPopupText>
            <ManualWrapper>
              <UserManual color={DefaultTheme.icon.blue} inMenu />
            </ManualWrapper>
            <MenuPopupText className="userProfilePopup" onClick={() => clearSessionCallback()}>
              {`${getTranslation('Log out')} (${process.env.REACT_APP_STAGE})`}
            </MenuPopupText>
          </ProfileMenuPopup>
        )}
      </MenuItem>
    </Container>
  );
}

export default HeaderMenu;
