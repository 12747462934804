import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const CardContainer = styled.div`
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  margin-bottom: 100vh;
`;

export default CardContainer;
