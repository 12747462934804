import BouleIcon from 'assets/icons/BouleIcon';
import HeaderMenu from 'components/header/HeaderMenu';
import locateEnvironment from 'helpers/environment/locate-environment';
import { Environments, TextColor } from 'interfaces/common';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.font.color.primary};
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const IconContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const EasterEgg = styled.div`
  font-size: 2em;
  color: white;
  margin-left: 0.5em;
`;

function Header({ clearSessionCallback }: { clearSessionCallback: () => void }) {
  return (
    <Container>
      <IconContainer to="/test">
        <BouleIcon width={94} height={25} color={TextColor.White} />
        {locateEnvironment([Environments.Dev, Environments.Cont, Environments.Test]) && <EasterEgg>💮</EasterEgg>}
      </IconContainer>
      <HeaderMenu clearSessionCallback={() => clearSessionCallback()} />
    </Container>
  );
}

export default Header;
