import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { useTheme } from 'app/AppStyling';
import CheckIcon from 'assets/icons/CheckIcon';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import CheckedInput from 'interfaces/checked-input';
import { AccessLimit } from 'interfaces/common';
import lodash from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

const ListTitleTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ListTitleText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.3em 0;
`;
const TitleTextItem = styled.div`
  font-size: 1em;
  font-weight: 700;
`;
const AccessTypeTextItem = styled.div`
  font-size: 0.8em;
  color: ${() => useTheme().font.color.secondary};
`;
const DropdownListItemContainer = styled.div<{ editAcs: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  padding: 1em 2em;
  cursor: ${({ editAcs }) => (editAcs ? 'pointer' : 'auto')};
  &:hover {
    background: ${({ editAcs }) => (editAcs ? useTheme().background.medium : 'transparent')};
  }
`;
const DropdownListItemText = styled.div`
  color: ${() => useTheme().font.color.primary};
`;

interface DropdownCheckListProps {
  title: string;
  dropdownBackground: string;
  editAccess: boolean;
  dropdownOptions: CheckedInput[];
  checkItemCallback?: (options: CheckedInput[]) => void;
}

const DefaultProps = {
  checkItemCallback: () => {},
};

function DropdownCheckList({
  title,
  dropdownBackground,
  editAccess,
  dropdownOptions,
  checkItemCallback,
}: DropdownCheckListProps) {
  const [open, setOpen] = useState(false);

  function handleCheckItem(input: CheckedInput) {
    if (editAccess && checkItemCallback) {
      const updatedOptions = [...dropdownOptions];
      const itemIndex = lodash.findIndex(updatedOptions, { value: input.value });
      updatedOptions.splice(itemIndex, 1, { ...input, isChecked: !input.isChecked });
      checkItemCallback(updatedOptions);
    }
  }

  function accessType(): string {
    const numOfItems = dropdownOptions.length;
    const numOfCheckedItems = dropdownOptions.filter((option) => option.isChecked).length;
    switch (numOfCheckedItems) {
      case 0:
        return AccessLimit.NoAccess;
      case numOfItems:
        return AccessLimit.FullAccess;
      default:
        return AccessLimit.PartialAccess;
    }
  }

  return (
    <Box
      sx={{
        bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
        width: '18em',
        background: dropdownBackground,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        height: 'fit-content',
      }}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          display: 'flex',
        }}
      >
        <ListTitleTextContainer>
          <ListTitleText>
            <TitleTextItem>{title}</TitleTextItem>
            <AccessTypeTextItem>{accessType()}</AccessTypeTextItem>
          </ListTitleText>
          <ExpandArrowIcon />
        </ListTitleTextContainer>
      </ListItemButton>
      {open &&
        dropdownOptions.map((option: CheckedInput) => (
          <DropdownListItemContainer key={option.value} editAcs={editAccess} onClick={() => handleCheckItem(option)}>
            <DropdownListItemText>{option.value}</DropdownListItemText>
            {option.isChecked && <CheckIcon />}
          </DropdownListItemContainer>
        ))}
    </Box>
  );
}

DropdownCheckList.defaultProps = DefaultProps;

export default DropdownCheckList;
