import InstrumentCycleCounter from '@dewire/models/definitions/api-response/instrument-cycle-counter';
import { getInstrumentTotalCycles } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import Flex from 'components/flex/Flex';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import { Divider, InfoTableRow } from 'components/styled-components/table/info-table-style';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

// TODO remove all .toString() in this file, temp solution for eslint error /rodi
export const CyclesContainer = styled.div`
  padding: 1em;
  background: ${() => useTheme().font.color.white};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 13em;
`;
const CycleItem = styled.div<{ width?: string }>`
  // property has poor usage but compiler provides nice fallbacks for many/all? browsers
  width: stretch;
  border-right: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  margin: 1em;
  :first-child {
    padding-left: 1em;
  }
  :last-child {
    border: none;
  }
  &:first-of-type {
    margin-left: 0;
  }
`;

function TotalCycles() {
  const { id } = useParams();
  const headersState = useAppSelector((state) => state.headers);

  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState('');
  const [instrumentLogCycles, setinstrumentLogCycles] = useState<InstrumentCycleCounter>();

  const fetchCycles = () => {
    if (id) {
      getInstrumentTotalCycles(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setinstrumentLogCycles(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
    }
  };

  useEffect(() => {
    fetchCycles();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (instrumentLogCycles || apiError !== '') setLoading(false);
    return () => {};
  }, [instrumentLogCycles, apiError]);

  return (
    <div>
      <CyclesContainer>
        {loading ? (
          <LoadingSpinner containerBased />
        ) : (
          <div>
            {apiError === '' ? (
              <>
                <InfoTableRow>
                  <CycleItem>
                    <TextWrapper size="large" bold>
                      Samples
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.countBlood !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.countBlood?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                  <CycleItem>
                    <TextWrapper size="large" bold>
                      Blank
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.countBlank !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.countBlank?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                  <CycleItem>
                    <TextWrapper size="large" bold>
                      Prime
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.countPrime !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.countPrime?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                  <CycleItem>
                    <TextWrapper size="large" bold>
                      Wash
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.countWash !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.countWash?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                </InfoTableRow>
                <Divider />
                <InfoTableRow>
                  <CycleItem width="33%">
                    <TextWrapper size="large" bold>
                      Orifice Clean
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.orificeManual !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.orificeManual?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                  <CycleItem width="33%">
                    <TextWrapper size="large" bold>
                      Orifice Clean Red
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.orificeAutoRed !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.orificeAutoRed?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                  <CycleItem width="33%">
                    <TextWrapper size="large" bold>
                      Orifice Clean White
                    </TextWrapper>
                    <Flex gap="5px" alignItems="flex-end">
                      {instrumentLogCycles?.orificeAutoWhite !== undefined ? (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">{instrumentLogCycles?.orificeAutoWhite?.toString()}</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}>cycles</TextWrapper>
                        </Flex>
                      ) : (
                        <Flex gap="5px" alignItems="flex-end">
                          <TextWrapper size="large">no data</TextWrapper>
                          <TextWrapper color={TextColor.Tertiary}> </TextWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </CycleItem>
                </InfoTableRow>
              </>
            ) : (
              <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>
            )}
          </div>
        )}
      </CyclesContainer>
    </div>
  );
}

export default TotalCycles;
