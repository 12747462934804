import { Customer } from '@dewire/models/definitions/api-response/customer';
import { CustomerDeleteResponse } from '@dewire/models/definitions/api-response/customer-delete-respons';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import { InstrumentDeleteResponse } from '@dewire/models/definitions/api-response/instrument-delete-respons';
import { InstrumentProductModel } from '@dewire/models/definitions/api-response/instrument-product-models';
import { Market } from '@dewire/models/definitions/api-response/market';
import Session from '@dewire/models/definitions/api-response/session';
import { Site } from '@dewire/models/definitions/api-response/site';
import { SiteDeleteResponse } from '@dewire/models/definitions/api-response/site-delete-respons';
import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addCustomer as addCustomerWithApi,
  addDistributor as addDistributorWithApi,
  addInstrument as addInstrumentWithApi,
  addSite as addSiteWithApi,
  disableCustomer as disableCustomerWithApi,
  disableDistributor as disableDistributorWithApi,
  disableInstrument as disableInstrumentWithApi,
  disableSite as disableSiteWithApi,
  getCustomers as getCustomersFromApi,
  getDistributors as getDistributorsFromApi,
  getInstrumentProductModels as getInstrumentProductModelsFromApi,
  getInstruments as getInstrumentsFromApi,
  getMarkets as getMarketsFromApi,
  getSites as getSitesFromApi,
  manageCustomer as manageCustomerWithApi,
  manageDistributor as manageDistributorWithApi,
  manageInstrument as manageInstrumentWithApi,
  manageSite as manageSiteWithApi,
} from 'api/Api';
import { LoadingState } from 'interfaces/common';
import { RequestStateWithContent } from 'interfaces/request-state';
import lodash from 'lodash';

interface AdminState {
  markets: RequestStateWithContent<Market>;
  models: RequestStateWithContent<InstrumentProductModel>;
  distributors: RequestStateWithContent<Distributor>;
  customers: RequestStateWithContent<Customer>;
  sites: RequestStateWithContent<Site>;
  instruments: RequestStateWithContent<Instrument>;
}

const initialState: AdminState = {
  markets: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  models: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  distributors: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  customers: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  sites: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  instruments: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const getMarkets = createAsyncThunk('admin/fetchMarkets', async (session: Session) =>
  getMarketsFromApi(session)
);

export const getInstrumentProductModels = createAsyncThunk('admin/fetchModels', async (session: Session) =>
  getInstrumentProductModelsFromApi(session)
);

export const getDistributors = createAsyncThunk('admin/fetchDistributors', async (session: Session) =>
  getDistributorsFromApi(session)
);

export const getCustomers = createAsyncThunk('admin/fetchCustomers', async (session: Session) =>
  getCustomersFromApi(session)
);

export const getSites = createAsyncThunk('admin/fetchSites', async (session: Session) => getSitesFromApi(session));

export const getInstruments = createAsyncThunk('admin/fetchInstruments', async (session: Session) =>
  getInstrumentsFromApi(session)
);

export const addDistributor = createAsyncThunk(
  'admin/addDistributor',
  async (payload: { session: Session; distributor: DistributorFormInput }) =>
    addDistributorWithApi(payload.session, payload.distributor)
);

export const addCustomer = createAsyncThunk(
  'admin/addCustomer',
  async (payload: { session: Session; customer: CustomerFormInput }) =>
    addCustomerWithApi(payload.session, payload.customer)
);

export const addSite = createAsyncThunk('admin/addSite', async (payload: { session: Session; site: SiteFormInput }) =>
  addSiteWithApi(payload.session, payload.site)
);

export const addInstrument = createAsyncThunk(
  'admin/addInstrument',
  async (payload: { session: Session; instrument: InstrumentFormInput }) =>
    addInstrumentWithApi(payload.session, payload.instrument)
);

export const manageDistributor = createAsyncThunk(
  'admin/manageDistributor',
  async (payload: { session: Session; distributor: DistributorFormInput }) =>
    manageDistributorWithApi(payload.session, payload.distributor)
);

export const manageCustomer = createAsyncThunk(
  'admin/manageCustomer',
  async (payload: { session: Session; customer: CustomerFormInput }) =>
    manageCustomerWithApi(payload.session, payload.customer)
);

export const manageSite = createAsyncThunk(
  'admin/manageSite',
  async (payload: { session: Session; site: SiteFormInput }) => manageSiteWithApi(payload.session, payload.site)
);

export const manageInstrument = createAsyncThunk(
  'admin/manageInstrument',
  async (payload: { session: Session; instrument: InstrumentFormInput }) =>
    manageInstrumentWithApi(payload.session, payload.instrument)
);

export const disableDistributor = createAsyncThunk(
  'admin/disableDistributor',
  async (payload: { session: Session; distributor: number }) =>
    disableDistributorWithApi(payload.session, payload.distributor)
);

export const disableCustomer = createAsyncThunk(
  'admin/disableCustomer',
  async (payload: { session: Session; customerInfo: CustomerDeleteResponse }) =>
    disableCustomerWithApi(payload.session, payload.customerInfo.customerId)
);

export const disableSite = createAsyncThunk(
  'admin/disableSite',
  async (payload: { session: Session; siteInfo: SiteDeleteResponse }) =>
    disableSiteWithApi(payload.session, payload.siteInfo.siteId)
);

export const disableInstrument = createAsyncThunk(
  'admin/disableInstrument',
  async (payload: { session: Session; instrumentInfo: InstrumentDeleteResponse }) =>
    disableInstrumentWithApi(payload.session, payload.instrumentInfo.instrumentSerial)
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Market cases
      .addCase(getMarkets.pending, (state) => ({
        ...state,
        markets: { ...state.markets, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getMarkets.fulfilled, (state, action) => ({
        ...state,
        markets: { ...state.markets, loadingStatus: LoadingState.Succeeded, content: action.payload.data.result },
      }))
      .addCase(getMarkets.rejected, (state, action) => ({
        ...state,
        markets: { ...state.markets, loadingStatus: LoadingState.Failed, error: action.error.message ?? null },
      }))
      // InstrumentProductModel cases
      .addCase(getInstrumentProductModels.pending, (state) => ({
        ...state,
        models: { ...state.models, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getInstrumentProductModels.fulfilled, (state, action) => ({
        ...state,
        models: { ...state.models, loadingStatus: LoadingState.Succeeded, content: action.payload.data.result },
      }))
      .addCase(getInstrumentProductModels.rejected, (state, action) => ({
        ...state,
        models: { ...state.models, loadingStatus: LoadingState.Failed, error: action.error.message ?? null },
      }))
      // Distributor cases
      .addCase(getDistributors.pending, (state) => ({
        ...state,
        distributors: { ...state.distributors, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getDistributors.fulfilled, (state, action) => ({
        ...state,
        distributors: {
          ...state.distributors,
          loadingStatus: LoadingState.Succeeded,
          content: action.payload.data.result,
        },
      }))
      .addCase(getDistributors.rejected, (state, action) => ({
        ...state,
        distributors: {
          ...state.distributors,
          loadingStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(addDistributor.pending, (state) => ({
        ...state,
        distributors: { ...state.distributors, updateStatus: LoadingState.Loading },
      }))
      .addCase(addDistributor.fulfilled, (state, action) => ({
        ...state,
        distributors: {
          ...state.distributors,
          content: state.distributors.content.concat(action.payload.data.result),
          updateStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(addDistributor.rejected, (state, action) => ({
        ...state,
        distributors: {
          ...state.distributors,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(manageDistributor.pending, (state) => ({
        ...state,
        distributors: { ...state.distributors, updateStatus: LoadingState.Loading },
      }))
      .addCase(manageDistributor.fulfilled, (state, action) => {
        const newDistributor: Distributor = action.payload.data.result;
        const distributorIndex = [...state.distributors.content].findIndex(
          (val: Distributor) => val.formData.id === newDistributor.formData.id
        );
        if (distributorIndex !== -1) {
          const updatedDistributors = [...state.distributors.content];
          updatedDistributors[distributorIndex] = { ...newDistributor };
          return {
            ...state,
            distributors: {
              ...state.distributors,
              content: updatedDistributors,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          distributors: {
            ...state.distributors,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(manageDistributor.rejected, (state, action) => ({
        ...state,
        distributors: {
          ...state.distributors,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(disableDistributor.pending, (state) => ({
        ...state,
        distributors: { ...state.distributors, updateStatus: LoadingState.Deleting },
      }))
      .addCase(disableDistributor.fulfilled, (state, action) => {
        const distributorId: number = action.payload.data.result;
        const updatedDistributors = [...state.distributors.content];
        const distributorIndex = updatedDistributors.findIndex((val: Distributor) => val.formData.id === distributorId);
        if (distributorIndex !== -1) {
          updatedDistributors.splice(distributorIndex, 1);
          return {
            ...state,
            distributors: {
              ...state.distributors,
              content: updatedDistributors,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          distributors: {
            ...state.distributors,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(disableDistributor.rejected, (state, action) => ({
        ...state,
        distributors: {
          ...state.distributors,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))

      // Customer cases
      .addCase(getCustomers.pending, (state) => ({
        ...state,
        customers: { ...state.customers, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getCustomers.fulfilled, (state, action) => ({
        ...state,
        customers: { ...state.customers, loadingStatus: LoadingState.Succeeded, content: action.payload.data.result },
      }))
      .addCase(getCustomers.rejected, (state, action) => ({
        ...state,
        customers: { ...state.customers, loadingStatus: LoadingState.Failed, error: action.error.message ?? null },
      }))
      .addCase(addCustomer.pending, (state) => ({
        ...state,
        customers: { ...state.customers, updateStatus: LoadingState.Loading },
      }))
      .addCase(addCustomer.fulfilled, (state, action) => {
        const newCustomer = action.payload.data.result;
        const updatedDistributors = [...state.distributors.content];
        const distributorIndex = updatedDistributors.findIndex(
          (val: Distributor) => val.formData.id === newCustomer.formData.distributorId
        );
        if (distributorIndex !== -1) {
          const newDistributor = updatedDistributors[distributorIndex];
          updatedDistributors[distributorIndex] = {
            ...newDistributor,
            numOfCustomers: newDistributor.numOfCustomers + 1,
          };
          return {
            ...state,
            customers: {
              ...state.customers,
              content: state.customers.content.concat(newCustomer),
              updateStatus: LoadingState.Succeeded,
            },
            distributors: {
              ...state.distributors,
              content: updatedDistributors,
            },
          };
        }
        return {
          ...state,
          customers: {
            ...state.customers,
            content: state.customers.content.concat(newCustomer),
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(addCustomer.rejected, (state, action) => ({
        ...state,
        customers: {
          ...state.customers,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(manageCustomer.pending, (state) => ({
        ...state,
        customers: { ...state.customers, updateStatus: LoadingState.Loading },
      }))
      .addCase(manageCustomer.fulfilled, (state, action) => {
        const newCustomer: Customer = action.payload.data.result;
        const updatedCustomers = [...state.customers.content];
        const customerIndex = updatedCustomers.findIndex(
          (val: Customer) => val.formData.id === newCustomer.formData.id
        );
        if (customerIndex !== -1) {
          updatedCustomers[customerIndex] = { ...newCustomer };
          return {
            ...state,
            customers: {
              ...state.customers,
              content: updatedCustomers,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          customers: {
            ...state.customers,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(manageCustomer.rejected, (state, action) => ({
        ...state,
        customers: {
          ...state.customers,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(disableCustomer.pending, (state) => ({
        ...state,
        customers: { ...state.customers, updateStatus: LoadingState.Deleting },
      }))
      .addCase(disableCustomer.fulfilled, (state, action) => {
        const customerInfo = action.payload.data.result;
        const updatedCustomers = [...state.customers.content];
        const customerIndex = updatedCustomers.findIndex(
          (val: Customer) => val.formData.id === customerInfo.customerId
        );
        if (customerIndex !== -1) {
          updatedCustomers.splice(customerIndex, 1);
          const updatedDistributors = [...state.distributors.content];
          const distributorIndex = updatedDistributors.findIndex(
            (val: Distributor) => val.formData.id === customerInfo.distributorId
          );
          if (distributorIndex !== -1) {
            const newDistributor = updatedDistributors[distributorIndex];
            updatedDistributors[distributorIndex] = {
              ...newDistributor,
              numOfCustomers: newDistributor.numOfCustomers - 1,
            };
            return {
              ...state,
              customers: {
                ...state.customers,
                content: updatedCustomers,
                updateStatus: LoadingState.Succeeded,
              },
              distributors: {
                ...state.distributors,
                content: updatedDistributors,
              },
            };
          }
        }
        return {
          ...state,
          customers: {
            ...state.customers,
            content: updatedCustomers,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(disableCustomer.rejected, (state, action) => ({
        ...state,
        customers: {
          ...state.customers,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))

      // FixSites: Redux handler
      // Site cases
      .addCase(getSites.pending, (state) => ({
        ...state,
        sites: { ...state.sites, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getSites.fulfilled, (state, action) => ({
        ...state,
        sites: {
          ...state.sites,
          loadingStatus: LoadingState.Succeeded,
          content: action.payload.data.result,
        },
      }))
      .addCase(getSites.rejected, (state, action) => ({
        ...state,
        sites: { ...state.sites, loadingStatus: LoadingState.Failed, error: action.error.message ?? null },
      }))
      .addCase(addSite.pending, (state) => ({
        ...state,
        sites: { ...state.sites, updateStatus: LoadingState.Loading },
      }))
      .addCase(addSite.fulfilled, (state, action) => {
        const newSite: Site = action.payload.data.result;
        const updatedCustomers = [...state.customers.content];
        const customerIndex = updatedCustomers.findIndex(
          (val: Customer) => val.formData.id === newSite.formData.customerId
        );
        if (customerIndex !== -1) {
          const customer = { ...updatedCustomers[customerIndex] };
          updatedCustomers[customerIndex] = {
            ...customer,
            numOfSites: customer.numOfSites + 1,
          };
          return {
            ...state,
            sites: {
              ...state.sites,
              content: state.sites.content.concat(action.payload.data.result),
              updateStatus: LoadingState.Succeeded,
            },
            customers: {
              ...state.customers,
              content: updatedCustomers,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          sites: {
            ...state.sites,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(addSite.rejected, (state, action) => ({
        ...state,
        sites: {
          ...state.sites,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(manageSite.pending, (state) => ({
        ...state,
        sites: { ...state.sites, updateStatus: LoadingState.Loading },
      }))
      .addCase(manageSite.fulfilled, (state, action) => {
        const newSite: Site = action.payload.data.result;
        const updatedSites = [...state.sites.content];
        const siteIndex = updatedSites.findIndex((val: Site) => val.formData.id === newSite.formData.id);
        if (siteIndex !== -1) {
          updatedSites[siteIndex] = { ...newSite };
          return {
            ...state,
            sites: {
              ...state.sites,
              content: updatedSites,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          sites: {
            ...state.sites,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(manageSite.rejected, (state, action) => ({
        ...state,
        sites: {
          ...state.sites,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(disableSite.pending, (state) => ({
        ...state,
        sites: { ...state.sites, updateStatus: LoadingState.Deleting },
      }))

      .addCase(disableSite.fulfilled, (state, action) => {
        const { customerId, siteId } = action.payload.data.result;
        const updatedSites = [...state.sites.content];
        const siteIndex = updatedSites.findIndex((val: Site) => val.formData.id === siteId);
        if (siteIndex !== -1) {
          updatedSites.splice(siteIndex, 1);
          const updatedCustomers = lodash.cloneDeep(state.customers.content); // change this to shallow copy once sites attribute is removed from Customer object
          const customerIndex = updatedCustomers.findIndex((val: Customer) => val.formData.id === customerId);
          if (customerIndex !== -1) {
            const newCustomer = updatedCustomers[customerIndex];
            updatedCustomers[customerIndex] = {
              ...newCustomer,
              numOfSites: newCustomer.numOfInstruments - 1,
            };
            return {
              ...state,
              sites: {
                ...state.sites,
                content: updatedSites,
                updateStatus: LoadingState.Succeeded,
              },
              customers: {
                ...state.customers,
                content: updatedCustomers,
                updateStatus: LoadingState.Succeeded,
              },
            };
          }
          return {
            ...state,
            sites: {
              ...state.sites,
              content: updatedSites,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          sites: {
            ...state.sites,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(disableSite.rejected, (state, action) => ({
        ...state,
        sites: {
          ...state.sites,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))

      // Instrument cases
      .addCase(getInstruments.pending, (state) => ({
        ...state,
        instruments: { ...state.instruments, loadingStatus: LoadingState.Loading },
      }))
      .addCase(getInstruments.fulfilled, (state, action) => ({
        ...state,
        instruments: {
          ...state.instruments,
          content: action.payload.data.result,
          loadingStatus: LoadingState.Succeeded,
        },
      }))
      .addCase(getInstruments.rejected, (state, action) => ({
        ...state,
        instruments: {
          ...state.instruments,
          loadingStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(addInstrument.pending, (state) => ({
        ...state,
        instruments: { ...state.instruments, updateStatus: LoadingState.Loading },
      }))
      .addCase(addInstrument.fulfilled, (state, action) => {
        const newInstrument = action.payload.data.result;
        const updatedDistributors: Distributor[] = [...state.distributors.content];
        const updatedCustomers: Customer[] = [...state.customers.content];
        const distributorIndex = updatedDistributors.findIndex(
          (val: Distributor) => val.formData.id === newInstrument.formData.distributorId
        );
        const customerIndex = updatedCustomers.findIndex(
          (val: Customer) => val.formData.id === newInstrument.formData.customerId
        );
        if (distributorIndex !== -1) {
          const newDistributor = updatedDistributors[distributorIndex];
          updatedDistributors[distributorIndex] = {
            ...newDistributor,
            numOfInstruments: newDistributor.numOfInstruments + 1,
          };
          if (customerIndex !== -1) {
            const newCustomer = updatedCustomers[customerIndex];
            updatedCustomers[customerIndex] = {
              ...newCustomer,
              numOfInstruments: newCustomer.numOfInstruments + 1,
            };
            return {
              ...state,
              instruments: {
                ...state.instruments,
                content: state.instruments.content.concat(newInstrument),
                updateStatus: LoadingState.Succeeded,
              },
              distributors: {
                ...state.distributors,
                content: updatedDistributors,
              },
              customers: {
                ...state.customers,
                content: updatedCustomers,
              },
            };
          }
          return {
            ...state,
            instruments: {
              ...state.instruments,
              content: state.instruments.content.concat(newInstrument),
              updateStatus: LoadingState.Succeeded,
            },
            distributors: {
              ...state.distributors,
              content: updatedDistributors,
            },
          };
        }
        return {
          ...state,
          instruments: {
            ...state.instruments,
            content: state.instruments.content.concat(newInstrument),
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(addInstrument.rejected, (state, action) => ({
        ...state,
        instruments: {
          ...state.instruments,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(manageInstrument.pending, (state) => ({
        ...state,
        instruments: { ...state.instruments, updateStatus: LoadingState.Loading },
      }))
      .addCase(manageInstrument.fulfilled, (state, action) => {
        const newInstrument: Instrument = action.payload.data.result;
        const updatedInstruments = [...state.instruments.content];
        const instrumentIndex = updatedInstruments.findIndex(
          (val: Instrument) => val.formData.id === newInstrument.formData.id
        );
        if (instrumentIndex !== -1) {
          updatedInstruments[instrumentIndex] = { ...newInstrument };
          return {
            ...state,
            instruments: {
              ...state.instruments,
              content: updatedInstruments,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          instruments: {
            ...state.instruments,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(manageInstrument.rejected, (state, action) => ({
        ...state,
        instruments: {
          ...state.instruments,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }))
      .addCase(disableInstrument.pending, (state) => ({
        ...state,
        instruments: { ...state.instruments, updateStatus: LoadingState.Deleting },
      }))
      .addCase(disableInstrument.fulfilled, (state, action) => {
        const instrumentSerial: string = action.payload.data.result.instrumentSerial.toString();
        const { distributorId, customerId } = action.payload.data.result;
        const updatedInstruments = [...state.instruments.content];
        const updatedDistributors: Distributor[] = [...state.distributors.content];
        const updatedCustomers: Customer[] = [...state.customers.content];
        const distributorIndex = updatedDistributors.findIndex((val: Distributor) => val.formData.id === distributorId);
        const customerIndex = updatedCustomers.findIndex((val: Customer) => val.formData.id === customerId);
        const instrumentIndex = updatedInstruments.findIndex(
          (val: Instrument) => val.formData.serialNumber === instrumentSerial
        );
        if (instrumentIndex !== -1) {
          updatedInstruments.splice(instrumentIndex, 1);
          if (distributorIndex !== -1) {
            const newDistributor = updatedDistributors[distributorIndex];
            updatedDistributors[distributorIndex] = {
              ...newDistributor,
              numOfInstruments: newDistributor.numOfInstruments - 1,
            };
            if (customerIndex !== -1) {
              const newCustomer = updatedCustomers[customerIndex];
              updatedCustomers[customerIndex] = {
                ...newCustomer,
                numOfInstruments: newCustomer.numOfInstruments - 1,
              };
              return {
                ...state,
                instruments: {
                  ...state.instruments,
                  content: updatedInstruments,
                  updateStatus: LoadingState.Succeeded,
                },
                distributors: {
                  ...state.distributors,
                  content: updatedDistributors,
                },
                customers: {
                  ...state.customers,
                  content: updatedCustomers,
                },
              };
            }
            return {
              ...state,
              instruments: {
                ...state.instruments,
                content: updatedInstruments,
                updateStatus: LoadingState.Succeeded,
              },
              distributors: {
                ...state.distributors,
                content: updatedDistributors,
              },
            };
          }
          return {
            ...state,
            instruments: {
              ...state.instruments,
              content: updatedInstruments,
              updateStatus: LoadingState.Succeeded,
            },
          };
        }
        return {
          ...state,
          instruments: {
            ...state.instruments,
            updateStatus: LoadingState.Succeeded,
          },
        };
      })
      .addCase(disableInstrument.rejected, (state, action) => ({
        ...state,
        instruments: {
          ...state.instruments,
          updateStatus: LoadingState.Failed,
          error: action.error.message ?? null,
        },
      }));
  },
});

export default adminSlice.reducer;
