import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import { Site } from '@dewire/models/definitions/api-response/site';
import RefreshIcon from 'assets/icons/RefreshIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import AddInstrumentModal from 'components/modals/AddInstrumentModal';
import FilterModal from 'components/modals/FilterModal';
import ManageInstrumentModal from 'components/modals/ManageInstrumentModal';
import Paginator from 'components/paginator/Paginator';
import FilterGroup from 'components/selection/filter/FilterGroup';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import RefreshContainer from 'components/styled-components/containers/RefreshContainer';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import SearchField from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllInstruments } from 'helpers/organization/getters';
import { manageExistingInstrument } from 'helpers/organization/manage';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { InstrumentModels, OrganizationParents, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';
import InstrumentRow from './InstrumentRow';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;
const SearchBarContainer = styled.div`
  width: 40em;
  ${() => bpTheme.breakpoints.down('lg')} {
    width: 30em;
  }
`;

interface InstrumentAdminViewProps {
  instrumentParent: {
    parent: Distributor | Customer | Site | undefined;
    parentType: OrganizationParents;
  };
  navigateCallback: (parent: Distributor | Customer, viewType: 'customers' | 'sites') => void;
}

function InstrumentAdminView({ instrumentParent, navigateCallback }: InstrumentAdminViewProps) {
  const [headersState, instrumentsState] = useAppSelector((state) => [state.headers, state.admin.instruments.content]);

  const [filter, setFilter] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(instrumentsState.length === 0);
  const [addInstrumentToggled, setAddInstrumentToggled] = useState(false);
  const [currentInstrument, setCurrentInstrument] = useState<Instrument>();
  const [sortedInstruments, setSortedInstruments] = useState<Instrument[]>([]);
  const [filteredInstruments, setFilteredInstruments] = useState<Instrument[]>([]);
  const [page, setPage] = useState(1);
  const pageLimit = 8;
  const isFilterActive = filter.length !== 0;
  const filterOptions = Object.values(InstrumentModels);

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const refreshInstruments = () => {
    setLoading(true);
  };

  const handleFilter = (option: string) => {
    const tempFilters = [...filter];
    if (filter.includes(option)) {
      setFilter(tempFilters.filter((element) => element !== option));
    } else {
      tempFilters.push(option);
      setFilter(tempFilters);
    }
  };

  const handleFilterClear = () => {
    setFilter([]);
  };

  const handleAddInstrumentClose = () => {
    setAddInstrumentToggled(false);
  };

  const saveManagedInstrument = (managedInstrument: Instrument) => {
    manageExistingInstrument(managedInstrument.formData, () => {
      provideSnackbar(
        Status.Success,
        lodash.capitalize(Status.Success),
        `Instrument "${currentInstrument?.serialNumber}" was successfully changed.`
      );
      setCurrentInstrument(undefined);
    });
  };

  const filterInstrumentsByParent = () => {
    const item = instrumentParent.parent;
    if (item) {
      switch (instrumentParent.parentType) {
        case OrganizationParents.Distributor:
          return instrumentsState.filter((instrument) => instrument.distributorName === item.formData.name);
        case OrganizationParents.Customer:
          return instrumentsState.filter((instrument) => instrument.customerName === item.formData.name);
        case OrganizationParents.Site:
          return instrumentsState.filter((instrument) => instrument.siteName === item.formData.name);
        default:
          break;
      }
    }
    return instrumentsState;
  };

  const getInstrumentParent = () => {
    switch (instrumentParent.parentType) {
      case OrganizationParents.Distributor:
        return {
          distributorId: instrumentParent.parent?.formData.id,
          customerId: undefined,
          siteId: undefined,
          parentType: instrumentParent.parentType,
        };
      case OrganizationParents.Customer:
        return {
          distributorId: (instrumentParent.parent as Customer).distributorId,
          customerId: instrumentParent.parent?.formData.id,
          siteId: undefined,
          parentType: instrumentParent.parentType,
        };
      case OrganizationParents.Site:
        return {
          distributorId: (instrumentParent.parent as Site).formData.distributorId,
          customerId: (instrumentParent.parent as Site).customerId,
          siteId: instrumentParent.parent?.formData.id,
          parentType: instrumentParent.parentType,
        };
      default:
        return {
          distributorId: undefined,
          customerId: undefined,
          siteId: undefined,
          parentType: instrumentParent.parentType,
        };
    }
  };

  useEffect(() => {
    if (loading) {
      getAllInstruments(() => setLoading(false));
    } else if (instrumentsState.length === 0) {
      provideSnackbar(Status.Info, 'Status', 'No instruments currently exist.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setSortedInstruments(lodash.sortBy(filterInstrumentsByParent(), (item) => item.serialNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentsState, instrumentParent]);

  useEffect(() => {
    if (filter.length > 0) {
      const modelInstruments = [...sortedInstruments].filter((instrument) =>
        filter.includes(instrument.formData.model)
      );
      const statusInstruments = [...sortedInstruments].filter(
        (instrument) => !instrument.formData.siteId && !instrument.formData.customerId
      );
      if (filter.includes('Unassigned instruments') && filter.length > 1) {
        setFilteredInstruments(lodash.intersection(modelInstruments, statusInstruments));
      } else if (filter.includes('Unassigned instruments')) {
        setFilteredInstruments(statusInstruments);
      } else {
        setFilteredInstruments(modelInstruments);
      }
    } else {
      setFilteredInstruments(sortedInstruments);
    }
  }, [sortedInstruments, filter]);

  const availableInstruments = filteredInstruments
    .filter(
      (instrument) =>
        !search.length ||
        (search.length && instrument.serialNumber.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && instrument.distributorName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && instrument.customerName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && instrument.country.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && instrument.city.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    )
    .map((_instrument) => (
      <InstrumentRow
        key={_instrument.formData.id}
        instrument={_instrument}
        customersCallback={(distributor: Distributor) => navigateCallback(distributor, 'customers')}
        sitesCallback={(customer: Customer) => navigateCallback(customer, 'sites')}
        manageInstrumentCallback={() => setCurrentInstrument(_instrument)}
      />
    ));

  return (
    <div>
      {currentInstrument && (
        <ManageInstrumentModal
          instrument={currentInstrument}
          onSaveCallback={(val: Instrument) => saveManagedInstrument(val)}
          onCloseCallback={() => setCurrentInstrument(undefined)}
        />
      )}
      {addInstrumentToggled && (
        <AddInstrumentModal parentToFilter={getInstrumentParent()} onCloseCallback={() => handleAddInstrumentClose()} />
      )}
      <ToolbarContainer>
        <ToolbarContainer>
          <SearchBarContainer>
            <SearchField
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              iconUrl={magnyfyingGlass}
            />
          </SearchBarContainer>
          <FilterModal isFiltering={isFilterActive} clearCallback={() => handleFilterClear()}>
            <>
              <FilterGroup
                groupTitle="Status"
                groupWidth={13.7}
                filterOptions={['Unassigned instruments']}
                filterArray={filter}
                onFilterCallback={(filterValue: string) => handleFilter(filterValue)}
              />

              <FilterGroup
                groupTitle="Brand"
                groupWidth={11}
                filterOptions={filterOptions}
                filterArray={filter}
                onFilterCallback={(filterValue: string) => handleFilter(filterValue)}
              />
            </>
          </FilterModal>
        </ToolbarContainer>
        <ToolbarContainer>
          {headersState.accesses.organization.instruments.edit && (
            <PrimaryButton rounded type="button" onClick={() => setAddInstrumentToggled(true)}>
              {getTranslation('Add instrument')}
            </PrimaryButton>
          )}
          <RefreshContainer onClick={() => refreshInstruments()}>
            <RefreshIcon />
          </RefreshContainer>
        </ToolbarContainer>
      </ToolbarContainer>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="17% 20% 23% 20% 18% 5%" textAlign="start">
            <p>{getTranslation('Instrument')}</p>
            <p>{getTranslation('Distributor')}</p>
            <p>{getTranslation('Customer')}</p>
            <p>{getTranslation('Country - City')}</p>
            <p>{getTranslation('Model')}</p>
            <div />
          </TableHeader>
          {loading ? (
            <LoadingSpinner containerBased />
          ) : (
            <div>{availableInstruments.slice(page * pageLimit - pageLimit, page * pageLimit)}</div>
          )}
        </Table>
      </HistoryTable>
      {availableInstruments.length > pageLimit && !loading && (
        <Paginator
          currentPage={page}
          maxPages={Math.ceil(availableInstruments.length / pageLimit)}
          paginationCallback={(_page: number) => handlePagination(_page)}
        />
      )}
    </div>
  );
}

export default InstrumentAdminView;
