import NavigationButton from 'components/styled-components/buttons/NavigationButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
`;
const AdaptableSecondaryButton = styled(SecondaryButton)`
  width: fit-content;
  padding: 0 1em;
`;

interface ButtonSetProps {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  primaryClickCallback?: () => void;
  secondaryClickCallback?: () => void;
  tertiaryClickCallback?: () => void;
}

const DefaultProps = {
  primary: '',
  secondary: '',
  tertiary: '',
  primaryClickCallback: () => {},
  secondaryClickCallback: () => {},
  tertiaryClickCallback: () => {},
};

function ButtonSet({
  primary,
  secondary,
  tertiary,
  primaryClickCallback,
  secondaryClickCallback,
  tertiaryClickCallback,
}: ButtonSetProps) {
  return (
    <ButtonContainer>
      {tertiary && tertiaryClickCallback && (
        <NavigationButton inContainer onClick={() => tertiaryClickCallback()}>
          <p>{tertiary}</p>
        </NavigationButton>
      )}
      {secondary && secondaryClickCallback && (
        <AdaptableSecondaryButton onClick={() => secondaryClickCallback()}>{secondary}</AdaptableSecondaryButton>
      )}
      {primary && primaryClickCallback && (
        <PrimaryButton onClick={() => primaryClickCallback()}>{primary}</PrimaryButton>
      )}
    </ButtonContainer>
  );
}

ButtonSet.defaultProps = DefaultProps;

export default ButtonSet;
