import InstrumentHeaderModel from '@dewire/models/definitions/api-response/instrument-header';
import { getInstrumentHeader } from 'api/Api';
import Flex from 'components/flex/Flex';
import Header2 from 'components/styled-components/headers/Header2';
import Header3 from 'components/styled-components/headers/Header3';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { Status } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import mail from '../../assets/icons/mail.svg';
import phone from '../../assets/icons/phone.svg';

interface IQcBannerProps {
  instrumentId: string;
}

interface HeaderContainerProps {
  minHeight?: string;
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  border-bottom: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  ${({ minHeight }) => (minHeight ? { 'min-height': `${minHeight}em` } : undefined)};
`;

const HeaderWrapper = styled.div`
  margin: 1em 0em 1em 2em;
  border-right: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  :last-child {
    border: none;
  }
  p {
    margin: 0.5em 0;
  }
`;

const FlexWithGap = styled(Flex)`
  gap: 2em;
  margin: 1em 0;
`;

function InstrumentHeader({ instrumentId }: IQcBannerProps) {
  const navigate = useNavigate();
  const headersState = useAppSelector((state) => state.headers);

  const [apiError, setApiError] = useState('');
  const [instrumentHeader, setInstrumentHeader] = useState<InstrumentHeaderModel>({
    customerName: '',
    siteName: '',
    city: '',
    adress: '',
    serialNumber: '',
    subSite: '',
    model: '',
    phoneNumber: '',
    email: '',
  });

  const fetchHeader = () => {
    if (instrumentId) {
      getInstrumentHeader(instrumentId, headersState).then((res) => {
        if (res.status === Status.Ok && res.data.result) {
          setInstrumentHeader(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
    } else navigate('/');
  };
  useEffect(() => {
    fetchHeader();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentId]);

  return (
    <HeaderContainer minHeight="9">
      {apiError === '' ? (
        <>
          <HeaderWrapper>
            <>
              <Header2 className="instrumentHeaderCustomerName">{instrumentHeader.customerName}</Header2>
              <Header3 className="instrumentHeaderSiteNameCity">
                {`${instrumentHeader.siteName}${instrumentHeader.city !== '' ? ',' : ''} ${instrumentHeader.city}`}
              </Header3>
            </>
            <TextWrapper className="address" subtitle>
              {instrumentHeader.subSite !== ''
                ? `${instrumentHeader.subSite}, ${instrumentHeader.adress}`
                : instrumentHeader.adress ?? ''}
            </TextWrapper>
          </HeaderWrapper>
          <HeaderWrapper>
            <TextWrapper size="x-large" className="hserialNumber">
              {instrumentHeader.serialNumber}
            </TextWrapper>
            <TextWrapper subtitle size="small">
              {instrumentHeader.model}
            </TextWrapper>
          </HeaderWrapper>
          <HeaderWrapper>
            <FlexWithGap alignItems="center">
              {instrumentHeader.phoneNumber && <img src={phone} alt="phone" />}
              <TextWrapper subtitle size="small">
                {instrumentHeader.phoneNumber}
              </TextWrapper>
            </FlexWithGap>
            <FlexWithGap alignItems="center">
              {instrumentHeader.email && <img src={mail} alt="mail" />}
              <TextWrapper subtitle size="small">
                {instrumentHeader.email}
              </TextWrapper>
            </FlexWithGap>
          </HeaderWrapper>
        </>
      ) : (
        <TextWrapper>{apiError}</TextWrapper>
      )}
    </HeaderContainer>
  );
}

export default InstrumentHeader;
