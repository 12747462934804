import { postSignOut, renewAccessTokens } from 'api/Api';
import FooterWrapper from 'components/footer/FooterWrapper';
import Header from 'components/header/Header';
import SnackbarCard from 'components/snackbar/SnackbarCard';
import { KEYCLOAK_ROOT_URL, LOGOUT_HOSTED_UI_URL } from 'config/config';
import dayjs from 'dayjs';
import clearUserSession from 'helpers/resets/clear-user-session';
import { useCallback, useEffect, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';
import { logActivity } from 'redux/slices/activity.slice';
import { store } from 'redux/store';
import Router from 'Router';
import styled from 'styled-components';

const AppContainer = styled.div`
  min-height: 100vh;
  margin: auto;
`;
const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.background.light};
  min-height: 90vh;
  padding: 0em 10em;
  display: flow-root;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1001;
`;

function AppContent() {
  const applicationRef = useRef<any>(null);
  const [headersState, activityState] = useAppSelector((state) => [state.headers, state.activity]);

  const clearSession = useCallback(
    (expired: boolean) => {
      const headersStateCopy = JSON.parse(JSON.stringify(headersState));
      const logoutCallback = () => {
        clearUserSession();

        if (!expired) {
          window
            .open(`https://${KEYCLOAK_ROOT_URL}/realms/boule-realm/protocol/openid-connect/logout`, '_blank')!
            .focus();
          window.location.href = LOGOUT_HOSTED_UI_URL;
        }
      };

      if (expired) {
        logoutCallback();
      } else {
        postSignOut(headersStateCopy).then(logoutCallback);
      }

      logoutCallback();
    },
    [headersState]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs().unix();
      const limit = headersState.timestamp;

      if (now >= limit && limit !== 0) {
        if (activityState.lastActive + 30 * 60 > dayjs().unix()) {
          renewAccessTokens(headersState);
        } else {
          clearSession(true);
        }
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [headersState, clearSession, activityState.lastActive]);

  useEffect(() => {
    const clickActionHandler = (event: MouseEvent) => {
      if (applicationRef.current && applicationRef.current.contains(event.target)) {
        store.dispatch(logActivity());
      }
    };
    document.addEventListener('mousedown', clickActionHandler);
    return () => {
      document.removeEventListener('mousedown', clickActionHandler);
    };
  }, [applicationRef]);

  return (
    <AppContainer ref={applicationRef}>
      <SnackbarCard />
      <HeaderWrapper>
        <Header clearSessionCallback={() => clearSession(false)} />
      </HeaderWrapper>
      <ContentContainer id="application-content-ref">
        <Router />
      </ContentContainer>
      <FooterWrapper />
    </AppContainer>
  );
}

export default AppContent;
