import { EqaReportResponse } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { useTheme } from 'app/AppStyling';
import DownloadIcon from 'assets/icons/DownloadIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import FilterModal from 'components/modals/FilterModal';
import InfoIconModal from 'components/modals/InfoIconModal';
import FilterGroup from 'components/selection/filter/FilterGroup';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { getReportAsPdf } from 'helpers/eqa/getters';
import { EQAFilterOptions } from 'helpers/eqa/usage';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import filterByString from 'helpers/filter/filter-handler';
import { formatEQADate, formatEQAMonth } from 'helpers/formatters/format-eqa-date';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { EQAValues, EQAValuesAsArray, LoadingState, Status, TextColor } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ReportGuideCard from './ReportGuideCard';

const ReportDetailsContainer = styled.div<{ isPdf: boolean | undefined }>`
  display: grid;
  background-color: ${() => useTheme().font.color.white};
  padding: 2em;
  margin: ${({ isPdf }) => (isPdf ? '0em 10em' : '0')};
  width: auto;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;
const ReportDetailsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 1em;
`;
const InfoIconWrapper = styled.button`
  background: transparent;
  position: relative;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const DownloadIconWrapper = styled.button`
  background: transparent;
  position: relative;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DetailsTextWrapper = styled.div<{ margin: boolean }>`
  padding: 0 1em;
  margin-left: ${({ margin }) => (margin ? '2em' : '0')};
`;
const ReportDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1em;
`;
const DetailsWrapper = styled.div`
  width: 20em;
  border: ${() => useTheme().lineWidth};

  ${() => bpTheme.breakpoints.down('lg')} {
    width: 17em;
  }
`;
const BorderDetailsWrapper = styled(DetailsWrapper)`
  border-right: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;

  ${() => bpTheme.breakpoints.down('sm')} {
    width: fit-content;
  }
`;
const ReportDetailsButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const DetailsText = styled(TextWrapper)<{ fontweight?: string }>`
  display: flex;
  margin-top: 10px;
  font-weight: ${({ fontweight }) => fontweight};
  overflow: none;
  line-clamp: none;
`;
const ButtonsContainer = styled.div`
  display: flex;
`;

interface ReportDetailsCardProps {
  report: EqaReportResponse;
  serialNumber: string;
  isPdf?: boolean;
  filterCallback?: (filters: EQAFilterOptions) => void;
}

const getDownloadPdfLink = (pdfAsBase64: string, fileName: string) => {
  const downloadLink = document.createElement('a');
  const linkSource = `data:application/pdf;base64,${pdfAsBase64}`;
  downloadLink.href = linkSource;

  downloadLink.download = fileName;
  return downloadLink;
};

const DefaultProps = {
  filterCallback: () => {},
  isPdf: false,
};

function ReportDetailsCard({ report, serialNumber, isPdf, filterCallback }: ReportDetailsCardProps) {
  const [status, setStatus] = useState(LoadingState.Idle);
  const [parameterFilter, setParameterFilter] = useState<string[]>([]);
  const [valueFilter, setValueFilter] = useState<string[]>([]);
  const [levelFilter, setlevelFilter] = useState<string[]>([]);
  const isFilterActive = parameterFilter.length !== 0 || valueFilter.length !== 0 || levelFilter.length !== 0;
  const { reportDetails } = report;

  const exportReport = () => {
    const onComplete = (pdfAsBase64: string) => {
      setStatus(LoadingState.Succeeded);
      const fileName = `eqa-report-${serialNumber}-${formatEQAMonth(reportDetails.date.month, true).toLowerCase()}-${
        reportDetails.date.year
      }.pdf`;
      getDownloadPdfLink(pdfAsBase64, fileName).click();
    };

    const onError = (message: string) => {
      setStatus(LoadingState.Failed);
      provideSnackbar(Status.Error, lodash.capitalize(Status.Error), message);
    };

    setStatus(LoadingState.Loading);
    getReportAsPdf(serialNumber, reportDetails, onComplete, onError);
  };

  const calculateLevelOptions = () => {
    if (reportDetails.numLabs) {
      const levelOptions = [];
      if (Number(reportDetails.numLabs.low)) levelOptions.push('low');
      if (Number(reportDetails.numLabs.normal)) levelOptions.push('normal');
      if (Number(reportDetails.numLabs.high)) levelOptions.push('high');
      return levelOptions;
    }
    return [];
  };

  const handleFilter = (selection: string, filterType: 'parameters' | 'values' | 'Levels') => {
    switch (filterType) {
      case 'parameters': {
        filterByString(parameterFilter, setParameterFilter, selection);
        break;
      }
      case 'values': {
        filterByString(valueFilter, setValueFilter, selection);
        break;
      }
      case 'Levels': {
        filterByString(levelFilter, setlevelFilter, selection);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleFilterClear = () => {
    setParameterFilter([]);
    setValueFilter([]);
    setlevelFilter([]);
  };

  const returnInstrumentInfo = (instrumentValue: string) =>
    !instrumentValue || instrumentValue === '' ? '-' : instrumentValue;

  useEffect(() => {
    if (filterCallback) filterCallback({ parameters: parameterFilter, values: valueFilter, levels: levelFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterFilter, valueFilter, levelFilter]);

  return (
    <ReportDetailsContainer isPdf={isPdf}>
      <ReportDetailsTitleWrapper>
        <TextWrapper bold>Boule EQA Monthly report</TextWrapper>
        {!isPdf && (
          <ButtonsContainer>
            {status !== LoadingState.Loading ? (
              <DownloadIconWrapper onClick={() => exportReport()} title="Download report as PDF">
                <DownloadIcon />
              </DownloadIconWrapper>
            ) : (
              <LoadingSpinner containerBased inButton />
            )}
            <FilterModal isFiltering={isFilterActive} leftAlign={-24.5} clearCallback={() => handleFilterClear()}>
              <>
                <FilterGroup
                  groupTitle="Parameters"
                  filterOptions={report.measurements.map((value) => value.key)}
                  filterArray={parameterFilter}
                  onFilterCallback={(filterValue) => handleFilter(filterValue, 'parameters')}
                />
                <FilterGroup
                  groupTitle="Values"
                  filterOptions={EQAValuesAsArray.map((value) => EQAValues[value])}
                  filterArray={valueFilter}
                  onFilterCallback={(filterValue) => handleFilter(filterValue, 'values')}
                />
                <FilterGroup
                  groupTitle="Level"
                  filterOptions={calculateLevelOptions()}
                  filterArray={levelFilter}
                  onFilterCallback={(filterValue) => handleFilter(filterValue, 'Levels')}
                />
              </>
            </FilterModal>
            <InfoIconWrapper>
              <InfoIconModal icon={<InfoIcon />} leftAlign={-18}>
                <ReportGuideCard />
              </InfoIconModal>
            </InfoIconWrapper>
          </ButtonsContainer>
        )}
      </ReportDetailsTitleWrapper>
      <ReportDetailsWrapper>
        <DetailsWrapper>
          <DetailsTextWrapper margin={false}>
            <DetailsText color={TextColor.Tertiary} fontweight={isPdf ? 'bold' : ''}>
              {formatEQADate(reportDetails.date)}
            </DetailsText>
            <DetailsText color={TextColor.Tertiary}>Lab ID: {returnInstrumentInfo(reportDetails.labId)}</DetailsText>
            <DetailsText color={TextColor.Tertiary}>Product: {returnInstrumentInfo(reportDetails.product)}</DetailsText>
            <DetailsText color={TextColor.Tertiary}>
              Instrument type: {returnInstrumentInfo(reportDetails.instrumentType)}
            </DetailsText>
            <DetailsText color={TextColor.Tertiary}>
              Instrument ID: {returnInstrumentInfo(reportDetails.serialNumber)}
            </DetailsText>
          </DetailsTextWrapper>
        </DetailsWrapper>
        <BorderDetailsWrapper>
          <DetailsTextWrapper margin>
            <DetailsText color={TextColor.Tertiary}>
              Lot kit: {returnInstrumentInfo(reportDetails.lotKitNumber)}
            </DetailsText>
            {reportDetails.lowLot && (
              <DetailsText color={TextColor.Tertiary}>Low lot: {reportDetails.lowLot}</DetailsText>
            )}
            {reportDetails.normLot && (
              <DetailsText color={TextColor.Tertiary}>Norm lot: {reportDetails.normLot}</DetailsText>
            )}
            {reportDetails.highLot && (
              <DetailsText color={TextColor.Tertiary}>High lot: {reportDetails.highLot}</DetailsText>
            )}
            <DetailsText color={TextColor.Tertiary}>
              Peer group: {returnInstrumentInfo(reportDetails.peerGroup)}
            </DetailsText>
          </DetailsTextWrapper>
        </BorderDetailsWrapper>
        {reportDetails.numLabs && (
          <DetailsWrapper>
            <DetailsTextWrapper margin>
              <DetailsText color={TextColor.Tertiary}>Num labs:</DetailsText>
              {reportDetails.numLabs.low !== 0 && (
                <DetailsText color={TextColor.Tertiary}>Low: {reportDetails.numLabs.low}</DetailsText>
              )}
              {reportDetails.numLabs.normal !== 0 && (
                <DetailsText color={TextColor.Tertiary}>Norm: {reportDetails.numLabs.normal}</DetailsText>
              )}
              {reportDetails.numLabs.high !== 0 && (
                <DetailsText color={TextColor.Tertiary}>High: {reportDetails.numLabs.high}</DetailsText>
              )}
            </DetailsTextWrapper>
          </DetailsWrapper>
        )}
      </ReportDetailsWrapper>
      <ReportDetailsButtonWrapper />
    </ReportDetailsContainer>
  );
}

ReportDetailsCard.defaultProps = DefaultProps;

export default ReportDetailsCard;
