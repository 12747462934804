import { AccessType, Permission, PermissionGroup } from '@dewire/models/definitions/api-response/access-type';
import { useTheme } from 'app/AppStyling';
import DropdownCheckList from 'components/lists/DropdownCheckList';
import CheckedInput from 'interfaces/checked-input';
import styled from 'styled-components';

const AccessOverview = styled.div`
  display: flex;
  margin: 1em 0;
`;
const LeftAccessOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 2em;
  border-right: 1px solid ${() => useTheme().background.medium};
  padding-right: 2em;
`;
const RightAccessOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 2em;
  padding-left: 2em;
`;

interface AccessContainerProps {
  accessOverview: AccessType | undefined;
}

function accessOptions(options: Permission[] | undefined): { options: CheckedInput[] } {
  return {
    options: options?.map((option) => ({ value: option.name, isChecked: option.active })) ?? [],
  };
}

function listAccess(access: PermissionGroup, background: string) {
  return (
    <DropdownCheckList
      key={access.name}
      title={access.name ?? ''}
      dropdownBackground={background}
      editAccess={false}
      dropdownOptions={accessOptions(access.permissions).options}
    />
  );
}

function AccessContainer({ accessOverview }: AccessContainerProps) {
  const backgroundGrey = useTheme().hoverBackground;
  const backgroundBlue = useTheme().background.blue;

  return (
    <AccessOverview id="accessOverview-accesses">
      <LeftAccessOverview>
        {accessOverview && accessOverview.basic.map((access) => listAccess(access, backgroundGrey))}
      </LeftAccessOverview>
      <RightAccessOverview>
        {accessOverview && accessOverview.admin.map((access) => listAccess(access, backgroundBlue))}
      </RightAccessOverview>
    </AccessOverview>
  );
}

export default AccessContainer;
