import { continuousUpdates, n1, n2, s1 } from 'helpers/variables/strings';
import styled from 'styled-components';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
const InfoRow = styled.div`
  font-size: small;
  text-align: left;
  gap: 0.5em;
`;
const InfoRowTitle = styled.span`
  font-weight: bold;
`;

function ReportGuideCard() {
  return (
    <InfoContainer>
      <InfoRow>
        <InfoRowTitle>S1: </InfoRowTitle>
        {s1}
      </InfoRow>
      <InfoRow>
        <InfoRowTitle>N1: </InfoRowTitle>
        {n1}
      </InfoRow>
      <InfoRow>
        <InfoRowTitle>N2: </InfoRowTitle>
        {n2}
      </InfoRow>
      <InfoRow>{continuousUpdates}</InfoRow>{' '}
    </InfoContainer>
  );
}

export default ReportGuideCard;
