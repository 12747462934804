import Paginator from 'components/paginator/Paginator';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import OriginUnit from 'interfaces/origin-unit';
import { useState } from 'react';
import styled from 'styled-components';

import OriginTableRow from './OriginTableRow';

const Table = styled.div`
  text-align: start;
`;
const OriginsTableHeader = styled(TableHeader)`
  padding-left: 0.8em;
`;

interface OriginsTableProps {
  units: OriginUnit[];
  originType: string;
  secondary?: boolean;
}

const DefaultProps = {
  secondary: false,
};

function OriginsTable({ units, originType, secondary }: OriginsTableProps) {
  const [page, setPage] = useState(1);
  const pageLimit = 8;

  function handlePagination(pageNumber: number) {
    setPage(pageNumber);
  }

  function returnTitles() {
    switch (originType) {
      case 'DISTRIBUTORS':
        return { first: 'Distributor', second: 'Country', third: 'City' };
      case 'CUSTOMERS':
        return { first: 'Customer', second: secondary ? 'Distributor' : 'Country', third: 'City' };
      case 'SITES':
        return {
          first: 'Site',
          second: secondary ? 'Customer' : 'City',
          third: secondary ? 'City - Address' : 'Address',
        };
      case 'SUBSITES':
        return {
          first: 'Subsite',
          second: 'City',
          third: 'Address',
        };
      case 'INSTRUMENTS':
        return { first: 'Serialnumber', second: 'Site', third: 'Model' };
      default:
        return { first: '', second: '', third: '' };
    }
  }

  return (
    <>
      <HistoryTable>
        <Table>
          <OriginsTableHeader gridExpression="35% 35% 30%" textAlign="start">
            <p>{returnTitles().first}</p>
            <p>{returnTitles().second}</p>
            <p>{returnTitles().third}</p>
            <div />
          </OriginsTableHeader>
          {units.length > 0 && (
            <div>
              {units.slice(page * pageLimit - pageLimit, page * pageLimit).map((originUnit) => (
                <div key={originUnit.id}>
                  <OriginTableRow unit={originUnit} />
                </div>
              ))}
            </div>
          )}
        </Table>
      </HistoryTable>
      {units.length > pageLimit && (
        <Paginator
          currentPage={page}
          maxPages={Math.ceil(units.length / pageLimit)}
          paginationCallback={(newPage: number) => handlePagination(newPage)}
        />
      )}
    </>
  );
}

OriginsTable.defaultProps = DefaultProps;

export default OriginsTable;
